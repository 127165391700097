import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserManagementService } from '../user-management.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdamConf } from '@app/app.config';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss']
})
export class TopicsComponent implements OnInit {

  resMsg: string;
  email: string;
  fname: string;
  lname: string;
  routerLink: string;
  isUserSetup: boolean;
  backEndValidationError: boolean;
  applicationError: boolean;
  isFavExist: boolean;
  topicsEdit = false;
  passedArgs = {};
  adam_labels = AdamConf;
  selectedTopics = [];
  allTopics = [];

  @Output() isUserSetupNext = new EventEmitter<string>();

  constructor(private userManagementService: UserManagementService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.routerLink = this.router.routerState.snapshot.url;

    if (this.routerLink.includes("/complete-registration-topics")) {
      this.isUserSetup = true;
      this.route.paramMap.subscribe(params => {

        this.passedArgs['uname'] = params.get("username");
        this.passedArgs['tmpPwd'] = params.get("tmppwd");
        this.passedArgs['code'] = params.get("code");
        this.passedArgs['token'] = params.get("token");
        this.passedArgs['fname'] = params.get("fname");
        this.fname = this.passedArgs['fname'];
        this.passedArgs['lname'] = params.get("lname");
        this.lname = this.passedArgs['lname']
        this.email = params.get("username");
        this.getTopic();
      })
    } else {
      this.isUserSetup = false;
      this.getTopic();
    }

  }

  topicEdit() {
    this.topicsEdit = true;
  }

  topicEditCancel() {
    if (this.isUserSetup) {
      this.router.navigate(['home']);
    }
    this.topicsEdit = false;
    this.getTopic();
  }

  selectTopic(topicsSelected) {
    this.allTopics.forEach(topics => {
      if (topicsSelected === 'all') {
        topics.isFavorite = true;
      } else {
        if (topicsSelected === topics.topicName) {
          if (topics.isFavorite === true) {
            topics.isFavorite = false;
          } else {
            topics.isFavorite = true;
          }
        }
      }
    });
  }

  getTopic() {
    if (this.isUserSetup === false) {
      this.selectedTopics = [];
      this.allTopics = [];
      this.userManagementService.getTopics().subscribe((topic) => {
        this.getTopicResponse(topic);
      });
    } else {
      this.userManagementService.getUserSetupTopic("", this.email).subscribe((topic) => {
        this.getTopicResponse(topic);
      });
    }
  }

  getTopicResponse(topic) {
    if (topic.responseCode === '2001') {
      topic.responseData.forEach(eachTopic => {
        this.allTopics.push({
          userTopicPreferenceId: eachTopic.userTopicPreferenceId,
          topicId: eachTopic.topicId,
          userId: eachTopic.userId,
          topicName: eachTopic.topicName,
          isFavorite: eachTopic.isFavorite
        })
        if (eachTopic.isFavorite === true) {
          this.selectedTopics.push({
            userTopicPreferenceId: eachTopic.userTopicPreferenceId,
            topicId: eachTopic.topicId,
            userId: eachTopic.userId,
            topicName: eachTopic.topicName,
            isFavorite: eachTopic.isFavorite
          })
        }
      });
    }
    else if (topic.responseCode === "4002") {
      this.backEndValidationError = true;
    } else if (topic.responseCode === "4001") {
      this.applicationError = true;
      this.resMsg = topic.responseMessage;
    }
  }

  topicSave() {
    if (this.isUserSetup === true) {
      this.allTopics.forEach(topics => {
        if (topics.isFavorite) {
          this.isFavExist = true
        } else {
          this.router.navigate(['complete-registration-login/' + this.passedArgs['uname'] + '/' + this.passedArgs['tmpPwd'] + '/' + this.passedArgs['token'] + '/' + this.fname + '/' + this.lname]);
        }
      })
      if (this.isFavExist) {
        this.userManagementService.postUserSetupTopics(this.allTopics, this.email).subscribe((topic) => {
          this.postTopicsResponse(topic);
          // isUserSetup      
        });
        this.router.navigate(['complete-registration-login/' + this.passedArgs['uname'] + '/' + this.passedArgs['tmpPwd'] + '/' + this.passedArgs['token'] + '/' + this.fname + '/' + this.lname]);
      }
    } else {
      this.userManagementService.postTopics(this.allTopics).subscribe((topic) => {
        this.postTopicsResponse(topic);
        // isUserSetup      
      });
    }
  }
  
  postTopicsResponse(topic) {
    if (topic.responseCode === '2001') {
      this.getTopic();
      this.topicsEdit = false;
    }
    else if (topic.responseCode === "4002") {
      this.backEndValidationError = true;
    } else if (topic.responseCode === "4001") {
      this.applicationError = true;
      this.resMsg = topic.responseMessage;
    }
  }
}
