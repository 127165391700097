import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauthorize',
  templateUrl: './unauthorize.component.html',
  styleUrls: ['./unauthorize.component.scss']
})
export class UnauthorizeComponent implements OnInit {

  constructor() { 
    // constructor
  }

  ngOnInit() {
    // onInit
  }

}
