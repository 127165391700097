import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'global-exception',
  templateUrl:'./global-exception.component.html',
})
 
export class GlobalExceptionComponent implements OnInit {
  title: string;
  closeBtnName: string;
  debug = true;
  code:any = '';
  url:any='';
  errorMsg:any= '';
  constructor(public bsModalRef: BsModalRef, private router: Router) {

  }
  goToHome(){
    this.router.navigate(["/"]);
  }
 
  ngOnInit() {
    //onInit
  }
}

