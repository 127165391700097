import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AdamConf } from '@app/app.config';

@Component({
  selector: 'app-materials-management-tile-magnifier',
  templateUrl: './materials-management-tile-magnifier.component.html',
  styleUrls: ['./materials-management-tile-magnifier.component.scss']
})
export class MaterialsManagementTileMagnifierComponent {
  @Input() showMagnifiedViewPopupData;
  @Output() showMagnifiedTilePopup = new EventEmitter<boolean>();
  public adamLabels = AdamConf;

  public showMagnifiedPopup(event): void {
    this.showMagnifiedViewPopupData = event;
    this.showMagnifiedTilePopup.emit(this.showMagnifiedViewPopupData);
  }
}
