import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, ViewChild, AfterViewInit, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { MousePosition } from '@app/shared/models/line-chart.model';
import * as D3 from 'd3';
import { ChartConfig, PieChartDataModel } from '../../models/pie-chart.model';

@Component({
  selector: 'app-pie-chart-view',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
  providers: [CurrencyPipe]
})
export class PieChartComponent implements AfterViewInit, OnChanges {
  @ViewChild('containerPieChart', { static: false }) element: ElementRef;

  @Input() title: string;
  @Input() dataFormat = 'number';
  @Input() chartConfig: ChartConfig = { margin: { top: 2, bottom: 2, right: 2, left: 2 } };
  @Input() textColor = '#000';
  @Input() pieData: PieChartDataModel[];

  private host: D3.Selection;
  private svg: D3.Selection;
  private radius: number;
  private htmlElement: HTMLElement;
  private width = 200;
  private height = 200;

  constructor(private currencyPipe: CurrencyPipe) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.pieData) {
      this.pieData = changes.pieData.currentValue;
      this.setup();
      this.buildSVG();
      this.buildPie();
    }
    if (changes && changes.title) {
      this.title = changes.title.currentValue;
    }
    if (changes && changes.width) {
      this.width = changes.width.currentValue;
    }
    if (changes && changes.height) {
      this.height = changes.height.currentValue;
    }
    if (changes && changes.textColor) {
      this.textColor = changes.textColor.currentValue;
    }
  }

  ngAfterViewInit() {
    this.width = this.element.nativeElement.offsetWidth - this.chartConfig.margin.left - this.chartConfig.margin.right;
    this.height = this.element.nativeElement.offsetHeight - this.chartConfig.margin.top - this.chartConfig.margin.bottom;
    this.htmlElement = this.element.nativeElement;
    this.host = D3.select(this.htmlElement);
    this.setup();
    this.buildSVG();
    this.buildPie();
  }

  private setup(): void {
    this.radius = Math.min(this.width, this.height) / 2;
  }

  private buildSVG(): void {
    if (this.host) {
      this.host.html('');
      this.svg = this.host
        .append('svg')
        .attr('viewBox', `0 0 ${this.width} ${this.height}`)
        .append('g')
        .attr('transform', `translate(${this.width / 2},${this.height / 2})`);
    }
  }

  private buildPie(): void {
    const pie = D3.layout.pie();
    if (this.pieData) {
      const values = this.pieData.map((data) => data.value);
      if (this.svg) {
        const arcSelection = this.svg
          .selectAll('.arc')
          .data(pie(values))
          .enter()
          .append('g')
          .attr('class', 'arc')
          .attr('class', 'arc')
          .on('mouseover', (d) => {
            const tooltipData = this.pieData.filter(item => item.tooltipData.length === 0);
            if (tooltipData.length === 0) {
              const duration = 200;
              const opacity = 0.9;
              const topAdj = 28;
              const div = D3.select('body').append('div')
                .attr('class', 'tooltip-chart-line')
                .style('opacity', 0);
              div.transition()
                .duration(duration)
                .style('opacity', opacity);
              div.html(this.getTooltipContent(d))
                .style('left', () => {
                  const leftAdj = 10;
                  const pos = this.positionTooltip(
                    {
                      x: D3.event.pageX,
                      y: D3.event.pageY
                    });
                  return (pos.left + leftAdj) + 'px';
                })
                .style('top', (D3.event.pageY - topAdj) + 'px')
                .style('z-index', '10001');
            }
          })
          .on('mouseout', () => {
            const tooltip = document.getElementsByClassName('tooltip-chart-line');
            while (tooltip[0]) {
              tooltip[0].parentNode.removeChild(tooltip[0]);
            }
            const duration = 500;
            const div = D3.select('tooltip-chart-line');
            div.transition()
              .duration(duration)
              .style('opacity', 0);
          });
        this.populatePie(arcSelection);
      }
    }
  }

  private populatePie(arcSelection: D3.Selection<D3.layout.pie.Arc>): void {
    const pieSizeIndex = 5;
    const outerRadius = this.radius - pieSizeIndex;
    const arc = D3.svg.arc<D3.layout.pie.Arc>().outerRadius(outerRadius);
    arcSelection
      .append('path')
      .attr('d', arc)
      .attr('fill', (_DATUM, index) => {
        return this.pieData[index].color;
      });

    arcSelection
      .append('text')
      .attr('transform', (datum: any) => {
        datum.innerRadius = 0;
        datum.outerRadius = outerRadius;
        return 'translate(' + arc.centroid(datum) + ')';
      })
      .text((_DATUM, index) => this.setDataFormat(this.pieData[index].label))
      .style('text-anchor', 'middle')
      .style('fill', this.textColor)
      .style('font-size', this.radius / 8);
  }
  private getTooltipContent(d): string {
    const toolTip = this.pieData.filter(item => item.value === d.value);
    let tooltipContent = '';
    tooltipContent = tooltipContent + '<table class="graph-table">';
    if (toolTip.length > 0) {
      toolTip[0].tooltipData.forEach(element => {
        tooltipContent += `<tr><td class="title">${element.label}:</td><td class="data-value"> ${element.value} </td></tr>`;
      });
    }
    tooltipContent = tooltipContent + '</table>';
    return tooltipContent;
  }

  private positionTooltip(mouse): MousePosition {
    const box = document.querySelector('.graph-table');
    const style = getComputedStyle(box);
    const toolwidth = parseInt(style.width);
    const toolMaxWidth = 100;
    // Distance of element from the right edge of viewport
    if (window.innerWidth - (mouse.x + toolwidth) < toolMaxWidth) { // If tooltip exceeds the X coordinate of viewport
      const mouseAdj = 30;
      mouse.x = mouse.x - toolwidth - mouseAdj;
    }
    return {
      top: mouse.y,
      left: mouse.x
    };
  }
  private setDataFormat(label) {
    let returnLabel = '';
    if (this.dataFormat === 'currency') {
      returnLabel = this.currencyPipe.transform(label, 'USD', 'symbol', '1.0-0');
    } else if (this.dataFormat === 'percentage' && label !== ''&& label !== '0') {
      returnLabel = label + '%';
    } else if (this.dataFormat === 'percentage' && label === '0') {
      returnLabel = '';
    } else {
      returnLabel = label;
    }
    return returnLabel;
  }
}
