import { Component, OnInit } from '@angular/core';
import { AdamConf } from '@app/app.config';import { StorageService } from 'src/app/core/storage.service';
import { BaseService } from 'src/app/core/base.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit {
  printPageType: string;
  printData;
  title: string;
  titleLine2: string;
  onDate: string;
  noteRowOne: string;
  noteRowTwo: string;
  noteRowThree: string;
  adam_labels;
  totalLabel: string;
  printDate: Date = new Date();
  printQRCode: string = null;
  browser: string = null;
  printDelayTime: number;
  user: any;
  isOpenAndHoldQuantityDisplay : any ;


  constructor(readonly storageService: StorageService, private baseService: BaseService, private authService: AuthService) {
    this.adam_labels = AdamConf;
  }

  ngOnInit() {
    this.user = this.authService.getLatestUserData();
    this.printDelayTime = 500;
    this.printData = JSON.parse(this.storageService.getItem("printData"));
    this.printPageType = this.printData.pageType;
    this.adam_labels = AdamConf;
    this.getBrowserName();
    switch (this.printPageType) {
      case this.adam_labels.print.replenish:
        this.noteRowOne = this.printData.mode === this.adam_labels.print.all ? this.adam_labels.print.replenishNote1_all : this.adam_labels.print.replenishNote1;
        this.noteRowTwo = this.adam_labels.print.replenishNote2;
        this.title = this.printData.mode === this.adam_labels.print.all ? this.adam_labels.print.Units :
          `${this.printData.unitName} - ${this.printData.coreFloor}, ${this.printData.coreName}`
        break;
      case this.adam_labels.print.order:
        this.noteRowOne = this.adam_labels.print.orderNote1;
        this.noteRowTwo = this.adam_labels.print.orderNote2;
        this.noteRowThree = this.adam_labels.print.orderNote3;
        this.title = this.adam_labels.print.Units;
        this.onDate = `${this.adam_labels.print.createdOn} ${this.printData.onDate}`
        break;
      case this.adam_labels.print.recall:
        this.noteRowOne = this.adam_labels.print.recallNote1;
        this.noteRowTwo = this.adam_labels.print.recallNote2;
        this.title = this.printData.productCode;
        this.onDate = `${this.adam_labels.recall_module.recalledOn} ${this.printData.onDate}`;
        if (this.printData.mode === this.adam_labels.print.all) {
          this.totalLabel = `${this.printData.totalUnits} ${this.printData.totalUnits > 1 ? this.adam_labels.print.units : this.adam_labels.print.unit}/${this.printData.tableData.length} ${this.printData.tableData.length > 1 ? this.adam_labels.print.totalStacks : this.adam_labels.print.totalStack}`
        } else {
          this.totalLabel = `${this.printData.tableData.length} ${this.printData.tableData.length > 1 ? this.adam_labels.print.totalStacks : this.adam_labels.print.totalStack}`
        }
        break;
      case this.adam_labels.print.expire:
        this.noteRowOne = this.adam_labels.print.expireNote1;
        this.noteRowTwo = this.adam_labels.print.expireNote2;
        this.title = this.printData.productCode;
        this.onDate = `${this.adam_labels.recall_module.expiringOn}`;
        // ${this.printData.onDate}
         if(this.printData.mode === this.adam_labels.print.all){
          this.totalLabel =`${this.printData.totalUnits} ${this.printData.totalUnits > 1 ? this.adam_labels.print.units : this.adam_labels.print.unit}/${this.printData.tableData.length} ${this.printData.tableData.length > 1 ? this.adam_labels.print.totalStacks : this.adam_labels.print.totalStack}` 
        }else{
          this.totalLabel = `${this.printData.tableData.length} ${this.printData.tableData.length > 1 ? this.adam_labels.print.totalStacks : this.adam_labels.print.totalStack}`}
        break;

      case this.adam_labels.print.caseStatus:
        this.title = `Dr. ${this.printData.name}`; // case #${this.printData.caseNumber}
        this.titleLine2 = `${this.printData.procedureName}`;
        this.noteRowOne = `Case #${this.printData.caseNumber}`; // Old code : this.adam_labels.print.caseStatusNote; this.printData.operationRoomName
        this.noteRowTwo = '';
        this.noteRowThree = this.adam_labels.print.caseStatusNote;
        this.printQRCode = this.printData.qrCodeValue;
        this.onDate = `${this.adam_labels.print.createdOn} ${this.printData.onDate}`;

        // Iterate over tableData to check the conditions
        for (let rowData of this.printData.tableData) {
          if (rowData.openQuantity !== 0 || rowData.holdQuantity !== 0) {
           this.isOpenAndHoldQuantityDisplay = true;
          }
        }
        break;

      case this.adam_labels.print.caseStatusAll:
        this.noteRowOne = this.adam_labels.print.caseStatusNote;
        this.noteRowTwo = '';
        this.noteRowThree = '';
        this.title = this.adam_labels.caseStatus_module.title;
        this.printDelayTime = 5000;
        this.printData.tableData.forEach(tableData => {
          tableData.pickRequestItems.forEach(rowData => {
            if (rowData.openQuantity !== 0 || rowData.holdQuantity !== 0) {
              this.isOpenAndHoldQuantityDisplay = true;
            }
          });
        });

        break;
    }

    setTimeout(() => {
      window.print();
    }, this.printDelayTime);
  }

  public getBrowserName() {
    const agent = navigator.userAgent.toLowerCase();
    if (agent.includes("edge")) {
      this.browser = 'edge';
    } else if (agent.includes("opr")) {
      this.browser = 'opera';
    } else if (agent.includes("chrome")) {
      this.browser = 'chrome';
    } else if (agent.includes("trident")) {
      this.browser = 'trident';
    } else if (agent.includes("firefox")) {
      this.browser = 'firefox';
    } else if (agent.includes("safari")) {
      this.browser = 'safari';
    } else {
      this.browser = 'other';
    }
  }

  getProductImageUrl(productCode) {
    return this.baseService.getImageURL('inventory-management/api/v1/public/products/images?productCode=' + productCode)
  }
}
