import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AdamConf } from '@app/app.config';
import { AuthService } from '../../auth/auth.service';
import { ForgotPasswordAPIResponse } from '../models/forgot-pwd-model';
import { StorageService } from '../storage.service';
@Component({
  selector: 'app-forgot-pwd',
  templateUrl: './forgot-pwd.component.html',
  styleUrls: ['./forgot-pwd.component.scss']
})
export class ForgotPwdComponent implements OnInit {
  forgotForm: FormGroup;
  val: any;
  requestSubmitted: boolean = false;
  submitted: boolean = false;
  errorMessage: any = '';
  adam_labels: any;
  requestOrigin: string;

  constructor(
   readonly route: Router, 
    private readonly authService: AuthService,
    private readonly storageService: StorageService) { }

  ngOnInit() {
    this.requestOrigin = this.storageService.getItem('hostName');
    this.adam_labels = AdamConf;
    this.forgotForm = new FormGroup({
      emailID: new FormControl('')
    });
  }

  get f() { return this.forgotForm.controls; }

  validateForm() {
    let valid = true;
    if (this.forgotForm.value.emailID === "") {
      this.forgotForm.controls.emailID.setErrors({ required: true });
      valid = false;
    }
    if (this.forgotForm.value.emailID !== "") {
      let regEx = new RegExp(/^(?!.*?[._\-@]{2})(?!^\.)(?!^\_)(?!^\-)(?!.*[-_.]$)[A-Za-z0-9_.-]+@[A-Za-z0-9.]+\.[A-Za-z]+$/);
      if (!regEx.test(this.forgotForm.value.emailID)) {
        this.forgotForm.controls.emailID.setErrors({ pattern: true });
        valid = false;
      }
    }
    return valid;
  }

  onForPassSubmit() {
    this.submitted = true;
    if (!this.validateForm()) {
      return false;
    }
    this.val = this.forgotForm.value;
    let params = { 
      "email": this.val.emailID.toUpperCase(),
      "requestOrigin": this.requestOrigin
    }
    this.authService.forgotPassword(params)
      .subscribe((response:ForgotPasswordAPIResponse) => {
        if (response.responseCode === '2001') {
          this.requestSubmitted = true;
        }
        else if (response.responseCode === '4002') {
          this.errorMessage = response.responseMessage;
        }
        else {
          this.errorMessage = "Operation failed. Please contact administrator!"
        }
      });
  }

  resetForm() {
    this.route.navigate(['home']);
  }
  cancelRequest() {
    this.route.navigate(['home']);
  }
}
