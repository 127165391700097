import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AdamConf } from '@app/app.config';
import { UtilityService } from '@app/shared/utility.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit{
  public adamLabels = AdamConf;
  public isMobileFlag: boolean;
  @Input('isOps') isOps;
  public currentYear: number;

  constructor(
    private readonly utilityService: UtilityService,
    private readonly router: Router
    ) { }

  public ngOnInit(): void {
    this.isMobileFlag = this.utilityService.isMobile();
    this.adamLabels = AdamConf;
    this.currentYear=(new Date()).getFullYear();
  }

  public navigateToHome(): void {
    if (this.isMobileFlag === true) {
      this.router.navigate(['inventories/hand-held-scanner/replenishment-summary']);
    } else {
      this.router.navigate(['home']);
    }
  }

}
