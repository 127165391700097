import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserManagementService } from '../user-management.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { AdamConf } from '@app/app.config';
import { GetNotificationWrapper } from '../shared/models/user-management-model';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  types: { label: string; value: boolean; }[];
  selectedType = true;
  ph: string;
  notifEdit = false;
  backEndValidationError: boolean;
  resMsg: string;
  applicationError: boolean;
  phOld: string;
  selectedTypeOld: boolean;
  notificationForm: FormGroup;
  routerLink: string;
  isUserSetup = true;
  // @Input()  name: string;
  adam_labels = AdamConf;
  passedArgs = {};
  email: string;
  fname: string;
  lname: string;

  @Output() isUserSetupNext = new EventEmitter<string>();

  constructor(private userManagementService: UserManagementService, private formBuilder: FormBuilder,
    private router: Router, private route: ActivatedRoute, private authService: AuthService) {
  }

  ngOnInit() {
    this.notificationForm = this.formBuilder.group({
      ph: ["", [Validators.required, Validators.minLength(10)]],
      selectedType: ["", Validators.required],
    });

    this.types = [
      { label: this.adam_labels.acount_settings_module.alertLabelYes, value: true },
      { label: this.adam_labels.acount_settings_module.alertLabelNo, value: false }
    ];
    this.routerLink = this.router.routerState.snapshot.url;
    if (this.routerLink.includes("/complete-registration-notifications")) {
      this.isUserSetup = true;
      this.notifEdit = true;
      this.route.paramMap.subscribe(params => {
        this.passedArgs['uname'] = params.get("username");
        this.passedArgs['tmpPwd'] = params.get("tmppwd");
        this.passedArgs['code'] = params.get("code");
        this.passedArgs['token'] = params.get("token");
        this.passedArgs['fname'] = params.get("fname");
        this.fname = this.passedArgs['fname'];
        this.passedArgs['lname'] = params.get("lname");
        this.lname = this.passedArgs['lname']
        this.email = params.get("username");
      })
      this.notificationForm.get('selectedType').setValue(true);
    } else {
      this.isUserSetup = false;
      this.getNotification();
    }
  }

  topicEdit() {
    this.notifEdit = true;
  }

  phSelect() {
    this.selectedType = this.notificationForm.value.selectedType;
    // this.notificationForm
    this.notificationForm.get('ph').setValue(this.phOld);
  }

  topicEditCancel() {
    if (this.isUserSetup) {
      this.router.navigate(['home']);
    }
    this.notifEdit = false;
    this.selectedType = this.selectedTypeOld;
    this.notificationForm.get('selectedType').setValue(this.selectedTypeOld);
    this.notificationForm.get('ph').setValue(this.phOld);

  }

  getNotification() {
    this.selectedType = true;
    if (this.isUserSetup === false) {
      this.userManagementService.getNotifi().subscribe((notifiData: GetNotificationWrapper) => {
        this.getNotificationSuccess(notifiData)
      });
    } else {
      this.notificationForm = this.formBuilder.group({
        ph: [" ", [Validators.required, Validators.minLength(10)]],
        selectedType: [this.selectedType, Validators.required],
      });
    }
  }

  getNotificationSuccess(notifiData: GetNotificationWrapper) {
    if (notifiData.responseCode === '2001') {
      this.ph = notifiData.responseData.phoneNumber;
      this.phOld = notifiData.responseData.phoneNumber;
      if (notifiData.responseData.isMobNotificationEnabled === null) {
        this.selectedType = false;
        this.selectedTypeOld = false;
      } else {
        if (notifiData.responseData.isMobNotificationEnabled === true) {
          this.selectedType = true;
          this.selectedTypeOld = notifiData.responseData.isMobNotificationEnabled;
        } else {
          this.selectedType = false;
          this.selectedTypeOld = notifiData.responseData.isMobNotificationEnabled;
        }
      }
      this.notificationForm = this.formBuilder.group({
        ph: [this.ph, [Validators.required, Validators.minLength(10)]],
        selectedType: [this.selectedType, Validators.required],
      });
    }
    else if (notifiData.responseCode === "4002") {
      this.backEndValidationError = true;
    } else if (notifiData.responseCode === "4001") {
      this.applicationError = true;
      this.resMsg = notifiData.responseMessage;
    }
  }

  save() {
    this.ph = this.notificationForm.value.ph;
    let phData = {
      "attributeName": "phoneNumber",
      "newValue": this.ph,
      "oldValue": this.phOld
    };
    let selectedData = {
      "attributeName": "isMobNotificationEnabled",
      "newValue": this.selectedType,
      "oldValue": this.selectedTypeOld
    }
    if (this.ph !== null && this.selectedType !== false) {
      this.notifiSave(phData)
    }
    this.notifiSave(selectedData)
  }

  notifiSave(data) {
    if (this.isUserSetup) {
      this.userManagementService.postUserSetupNotif(data, this.email).subscribe((topic) => {
        this.saveResponse(topic)
      });
      this.authService.logout(this.email).then(
        logoutResponse => {
          console.log(logoutResponse);
          // { path : 'complete-registration-login/:username/:tmppwd/:token/:fname/:lname', component:UserSetupComponent},
          this.router.navigate(['complete-registration-login/' + this.passedArgs['uname'] + '/' + this.passedArgs['tmpPwd'] + '/' + this.passedArgs['token'] + '/' + this.fname + '/' + this.lname]);
        }
      );
    } else {
      this.userManagementService.postNotifi(data).subscribe((topic) => {
        this.saveResponse(topic)
      });
    }
  }

  saveResponse(topic) {
    if (topic.responseCode === '2001') {
      this.getNotification();
      this.notifEdit = false;
    }
    else if (topic.responseCode === "4002") {
      this.backEndValidationError = true;
    } else if (topic.responseCode === "4001") {
      this.applicationError = true;
      this.resMsg = topic.responseMessage;
    }
  }
}
