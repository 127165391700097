
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

const SECRET_KEY = 'unuweiurwyerwus';

@Injectable({
    providedIn: "root"
})

export class StorageService {
    constructor() {
        //constructor
    }

    private encrypt = function encrypt(data) {
        data = CryptoJS.AES.encrypt(data, SECRET_KEY);
        data = data.toString();
        return data;
    }
    private decrypt = function decrypt(data) {
        try {
            data = CryptoJS.AES.decrypt(data, SECRET_KEY);
            if (data.toString()) {
                return data.toString(CryptoJS.enc.Utf8);
            }
        }
        catch (error) {
            data = null;
        }
        return data;
    }

    //Localstorage methods
    setItem(key, value) {
        localStorage.setItem(key, this.encrypt(value));

    }
    getItem(key) {
        return this.decrypt(localStorage.getItem(key));
    }
    removeItem(key) {
        localStorage.removeItem(key);
    }

    //session methods
    setSessionItem(key, value) {
        value = value.toString();
        sessionStorage.setItem(key, this.encrypt(value));
    }
    getSessionItem(key) {
        return this.decrypt(sessionStorage.getItem(key));
    }
    removeSessionItem(key) {
        sessionStorage.removeItem(key);
    }
}