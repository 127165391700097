import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AdamConf } from '@app/app.config';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss']
})
export class CaptchaComponent implements OnInit {
  @Output() captchaResponseHandler = new EventEmitter<string>();
  public adamLabels = AdamConf;

  constructor() { }

  ngOnInit() { }

  resolved(captchaResponse: string): void {
    this.captchaResponseHandler.emit(captchaResponse);
  }

  errored(): void {
    console.warn(`reCAPTCHA error encountered`);
    this.captchaResponseHandler.emit(null);
  }

}
