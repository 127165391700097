import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ClinicalService } from '../clinical.service';
import { Subscription, timer } from 'rxjs';
import { TileDataApiResponse, TileFilterInfo } from '../../models/clinical-dashboard-model';
import { AdamConf } from '@app/app.config';
import { LoggerService } from '@app/core/logger.service';
import { AuthService } from '@app/auth/auth.service';

@Component({
  selector: 'app-financial-overview-tile',
  templateUrl: './financial-overview-tile.component.html',
  styleUrls: ['./financial-overview-tile.component.scss']
})
export class FinancialOverviewTileComponent implements OnInit, OnDestroy {

  public isDisplayFilter = false;
  public filterConfig: any;
  public adamLabels = AdamConf;
  public cumulativeYTDBudget: string;
  public cumulativeYTDActual: string;
  public deltaYTDDollars: string;
  public deltaYTDPercent: string;
  public deltaYTDIndicator: string;
  public yearEndForecastBudget: string;
  public yearEndForecastActual: string;
  public forecastedYearEndDeltaDollars: string;
  public forecastedYearEndDeltaPercent: string;
  public forecastedYearEndDeltaIndicator: string;
  private filterSubscription$: Subscription;
  private dataSubscription$: Subscription;
  private ticker$: Subscription;
  @Input() showMagnifiedViewPopupData: any;
  @Output() showMagnifiedPopup = new EventEmitter<string[]>();
  public lineChartData: any;
  public chartMetaData: any;
  public financialOverviewParams: TileFilterInfo;
  public deltaYTDDirection: string;
  public forecastedYearEndDeltaDirection: string;

  constructor(
    private readonly clinicalService: ClinicalService,
    private readonly logger: LoggerService,
    private readonly authService: AuthService
  ) {
    this.chartMetaData = {
      type: 'financial-overview-tile',
      xAxis: 'date',
      yAxis: 'currency',
      margin: {
        top: 20,
        right: 20,
        bottom: 45,
        left: 50
      }
    };
  }

  public ngOnInit(): void {
    this.loadFinancialOverviewTileData();
    this.setAutoRefresh();
  }

  public ngOnDestroy(): void {
    this.resetFinancialOverviewSubscriptions();
    if (this.ticker$) {
      this.ticker$.unsubscribe();
    }
  }

  private resetFinancialOverviewSubscriptions(): void {
    if (this.dataSubscription$) {
      this.dataSubscription$.unsubscribe();
    }
    if (this.filterSubscription$) {
      this.filterSubscription$.unsubscribe();
    }
  }

  private setAutoRefresh(): void {
    const currentUser = this.authService.getCurrentUser();
    if (currentUser.isAutoRefreshEnabled === true && this.showMagnifiedViewPopupData.isMagnifiedView !== true) {
      const delayToStartTimer = currentUser.autoRefreshTimeInterval;
      const timerInterval = delayToStartTimer;
      const source = timer(delayToStartTimer, timerInterval);
      this.ticker$ = source.subscribe((val) => {
        this.logger.log('Refreshing Financial Overview tile data');
        this.loadFinancialOverviewTileData();
      });
    }
  }

  private loadFinancialOverviewTileData(): void {
    this.resetFinancialOverviewSubscriptions();
    this.filterSubscription$ = this.clinicalService.financialOverviewFilter$.subscribe((data) => {
      if (data) {
        this.financialOverviewParams = {
          tileName: 'Financial Overview',
          tileFilters: data
        };
        this.getDataByFilters();
      }
    });
  }

  private getDataByFilters(): void {
    if (this.dataSubscription$) {
      this.dataSubscription$.unsubscribe();
    }
    this.dataSubscription$ = this.clinicalService.getClinicalTileData(this.financialOverviewParams)
      .subscribe((dataItems) => {
        if (dataItems.responseCode === '2001') {
          this.lineChartData = dataItems.responseData;
          if (dataItems.responseData.calloutData && dataItems.responseData.calloutData.length > 0) {
            this.setFinancialOverviewData(dataItems);
          }
        }
      });
  }

  private setFinancialOverviewData(dataItems: TileDataApiResponse): void {
    dataItems.responseData.calloutData.forEach((tileData) => {
      switch (tileData.label) {
        case 'Cumulative YTD':
          this.cumulativeYTDBudget = tileData.firstDetailValue;
          this.cumulativeYTDActual = tileData.secondDetailValue;
          break;
        case 'Delta YTD':
          this.deltaYTDDollars = tileData.firstDetailValue;
          this.deltaYTDPercent = tileData.secondDetailValue;
          this.deltaYTDIndicator = tileData.trendColor.toUpperCase();
          this.deltaYTDDirection = (tileData.trendDirection !== null) ? tileData.trendDirection.toUpperCase() : tileData.trendDirection;
          break;
        case 'Year-End Forecast':
          this.yearEndForecastBudget = tileData.firstDetailValue;
          this.yearEndForecastActual = tileData.secondDetailValue;
          break;
        case 'Forecasted Year-End Delta':
          this.forecastedYearEndDeltaDollars = tileData.firstDetailValue;
          this.forecastedYearEndDeltaPercent = tileData.secondDetailValue;
          this.forecastedYearEndDeltaIndicator = tileData.trendColor.toUpperCase();
          this.forecastedYearEndDeltaDirection = (tileData.trendDirection !== null) ? tileData.trendDirection.toUpperCase() : tileData.trendDirection;
          break;
      }
    });
  }

  public displayFilter(): void {
    this.isDisplayFilter = true;
    this.filterConfig = {
      isDisplayFilter: this.isDisplayFilter,
      tile: 'Financial Overview'
    };
    this.clinicalService.setFilterPopupState(this.filterConfig);
    this.showMagnifiedViewPopupData.isMagnifiedView = false;
  }

  public showMagnifiedView(): void {
    this.showMagnifiedViewPopupData.isMagnifiedView = (this.showMagnifiedViewPopupData.isMagnifiedView === true) ? false : true;
    this.showMagnifiedViewPopupData.tile =
      (this.showMagnifiedViewPopupData.isMagnifiedView === true) ? this.adamLabels.clinical_module.financialOverviewLabel : '';
    this.showMagnifiedPopup.emit(this.showMagnifiedViewPopupData);
  }

}
