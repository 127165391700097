import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { GlobalSpinnerComponent } from './core/global-spinner/global-spinner.component';
import { SharedModule } from './shared/shared.module';
import { PDialogComponent } from './util/p-dialog/p-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TokenHandlerInterceptor } from './core/token-handler-interceptor';
import { ErrorHandlerInterceptor } from './core/error-handler-interceptor';
import { AccordionModule } from 'primeng/accordion';
// import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';

@NgModule({
  declarations: [
    AppComponent,
    GlobalSpinnerComponent,
    PDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    MatDatepickerModule,
    NgHttpLoaderModule.forRoot(),
    AccordionModule,
    // AmplifyAngularModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenHandlerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    // AmplifyService,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  entryComponents: [
    GlobalSpinnerComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
