import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Router } from '@angular/router';
import { AdamConf } from '@app/app.config';
import { UtilityService } from '@app/shared/utility.service';
import { AuthService } from 'src/app/auth/auth.service';

import { LoginAPiResponse } from '../models/forgot-pwd-model';
import { TokenApiResponse } from '../models/login-model';
import { StorageService } from '../storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  signedIn = false;
  user: any;
  greeting: any = '';

  submitted: boolean = false;
  loginForm: FormGroup;
  loginFormVal: any;
  invalidUserOrPwd: boolean = false;
  loginProgress = false;
  alreadyLogged = false;
  routerLink: string;
  isUserSetup: boolean;
  adam_labels: any;
  signInErrorMsg: any;
  private isMobileFlag: boolean;
  requestOrigin: string;

  constructor(
    private readonly router: Router,
    private readonly utilityService: UtilityService,
    readonly authService: AuthService,
    private storageService: StorageService) {
    const userData = this.authService.getCurrentUser();
    this.alreadyLogged = userData && userData.userId ? true : false;
  }


  ngOnInit() {
    this.requestOrigin = this.storageService.getItem('hostName');
    this.adam_labels = AdamConf;
    this.loginForm = new FormGroup({
      email: new FormControl(''),
      password: new FormControl('')
    });
    this.isMobileFlag = this.utilityService.isMobile();

    this.routerLink = this.router.routerState.snapshot.url;
    if (this.routerLink.includes("/complete-registration-login")) {
      this.isUserSetup = true;
    } else {
      this.isUserSetup = false;
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  validateForm() {
    let valid = true;
    if (this.loginForm.value.email === "") {
      this.loginForm.controls.email.setErrors({ required: true });
      valid = false;
    }
    if (this.loginForm.value.password === "") {
      this.loginForm.controls.password.setErrors({ required: true });
      valid = false;
    }
    if (this.loginForm.value.email !== "") {
      let regEx = new RegExp(/^(?!.*?[._\-@]{2})(?!^\.)(?!^\_)(?!^\-)(?!.*[-_.]$)[A-Za-z0-9_.-]+@[A-Za-z0-9.]+\.[A-Za-z]+$/);
      if (!regEx.test(this.loginForm.value.email)) {
        this.loginForm.controls.email.setErrors({ pattern: true });
        valid = false;
      }
    }
    return valid;
  }

  onLoginSubmit() {
    //check for existing session
    if (this.authService.getCurrentUser()) {
      //aleady have session so redirect to dashboard.
      window.location.href = "/dashboard";
    } else {
      this.onLoginSubmitConf();
      if (this.loginForm.invalid === false) {
        let queryParams = {
          "email": this.loginFormVal.email.toUpperCase(),
          "password": this.loginFormVal.password,
          "requestOrigin": this.requestOrigin
        }
        this.authService.login(queryParams).subscribe(
          (response: LoginAPiResponse) => {
            if (response.responseCode === "2001") {
              let queryParams1 = {
                "username": this.loginFormVal.email.toUpperCase(),
                "password": this.loginFormVal.password,
                "grant_type": "password"
              }
              this.authService.autherizeUser(queryParams1).subscribe(
                (autherizeUserResponse: TokenApiResponse) => {
                  this.authService.setSession(autherizeUserResponse).then((data: boolean) => {
                    this.authService.getAutoRefreshPreferences().subscribe((autoRefreshApiResponse) => {
                      if (autoRefreshApiResponse.responseCode === '2001') {
                        this.authService.addAutoRefreshPreferenceInSession(autoRefreshApiResponse.responseData);
                      }
                    });
                    this.authService.setUserPermissionData().then((setUserPermissionResponse: boolean) => {
                      this.authService.initiateNotificationInfoOnLogin();
                      if (this.isMobileFlag === true) {
                        this.router.navigate(['inventories/hand-held-scanner/replenishment-summary']);
                      } else {
                        this.router.navigate(['dashboard']);
                      }
                    });
                  });

                },
                error => {
                  this.authService.logout().then((data: boolean) => {
                    this.router.navigate(['login']);
                  });

                }
              );
            }
            else if (response.responseCode == "4002") {
              if (response.responseMessage == "Your password has expired. Please reset your password.") {
                this.router.navigate(['password-expired/' + this.loginFormVal.email]);
              }
              else {
                this.signInErrorMsg = response.responseMessage;
                this.signInError();
              }
            }
          }
        );

      }
    }
  }

  signInFn(response) {
    //Store the logged in user email in localstorage to  access across the app.
    //localStorage.setItem('loggedInUserEmail', response.attributes.email);

    //reset pwd challange
    if (response.challengeName && response.challengeName === 'NEW_PASSWORD_REQUIRED') {
      this.router.navigate(['/complete-registration/' + this.loginFormVal.email + '/' + this.loginFormVal.password]);
    }
    // success login
    else if (response.signInUserSession) {
      this.router.navigate(['dashboard']);
    }
  }

  signInError() {
    this.loginProgress = false;
    this.loginForm.enable();
    this.invalidUserOrPwd = true;

  }

  onLoginSubmitConf() {
    this.submitted = true;
    this.invalidUserOrPwd = false;
    if (!this.validateForm()) {
      return false;
    }
    this.loginFormVal = this.loginForm.value;
    this.loginProgress = true;
    this.loginForm.disable();
  }
  gotoForgotPwd() {
    this.router.navigate(['forgot-password']);
  }

  resetForm() {
    this.loginForm.reset();
    this.submitted = false;
    this.invalidUserOrPwd = false;
    this.router.navigate(['home']);
  }

  requestAccess() {
    this.router.navigate(['request-access']);
  }


}
