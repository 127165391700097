import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GlobalExceptionComponent } from './global-exception/global-exception.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { AlertModule } from 'ngx-bootstrap';
import { HomeComponent } from './home/home.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';
import { ForgotPwdComponent } from './forgot-pwd/forgot-pwd.component';
import { RequestAccessComponent } from './request-access/request-access.component';
import { MaterialModule } from '../shared/material/material.module';
import { ResetSuccessComponent } from './reset-success/reset-success.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import { PrivacyPolicyComponent } from './footer/privacy-policy/privacy-policy.component';
import { CustomerSupportComponent } from './footer/customer-support/customer-support.component';
import { LegalNoticeComponent } from './footer/legal-notice/legal-notice.component';
import { NotificationListComponent } from './notification/notification-list/notification-list.component';
import { UserSetupComponent } from './user-setup/user-setup.component';
import { UnauthorizeComponent } from './unauthorize/unauthorize.component';
import { ClinicalContainerComponent } from './dashboard/clinical/clinical-container/clinical-container.component';
import { PreferenceCardOverviewTileComponent } from './dashboard/clinical/preference-card-overview-tile/preference-card-overview-tile.component';
import { InventoryOverviewTileComponent } from './dashboard/clinical/inventory-overview-tile/inventory-overview-tile.component';
import { PreferenceCardAccuracyTileComponent } from './dashboard/clinical/preference-card-accuracy-tile/preference-card-accuracy-tile.component';
import { OrScanningTileComponent } from './dashboard/clinical/or-scanning-tile/or-scanning-tile.component';
import { ProductsUsageReturnsTileComponent } from './dashboard/clinical/products-usage-returns-tile/products-usage-returns-tile.component';
import { FinancialOverviewTileComponent } from './dashboard/clinical/financial-overview-tile/financial-overview-tile.component';
import { TileFilterComponent } from './dashboard/clinical/tile-filter/tile-filter.component';
import { TileMagnifierComponent } from './dashboard/clinical/tile-magnifier/tile-magnifier.component';
import { StackedBarChartComponent } from './dashboard/clinical/tile-graphs/stacked-bar-chart/stacked-bar-chart.component';
import { LineChartClinicalComponent } from './dashboard/clinical/tile-graphs/line-chart-clinical/line-chart-clinical.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TemporaryContainerComponent } from './dashboard/temporary/temporary-container/temporary-container.component';
import { MaterialsManagementContainerComponent } from './dashboard/materials-management/materials-management-container/materials-management-container.component';
import { MaterialsManagementTileFilterComponent } from './dashboard/materials-management/materials-management-tile-filter/materials-management-tile-filter.component';
import { MaterialsManagementTileMagnifierComponent } from './dashboard/materials-management/materials-management-tile-magnifier/materials-management-tile-magnifier.component';
import { PickingSummaryTileComponent } from './dashboard/materials-management/picking-summary-tile/picking-summary-tile.component';
import { SummaryTileComponent } from './dashboard/materials-management/summary-tile/summary-tile.component';
import { TodayProgressTrackerTileComponent } from './dashboard/materials-management/today-progress-tracker-tile/today-progress-tracker-tile.component';
import { DispensedRestockedTileComponent } from './dashboard/materials-management/dispensed-restocked-tile/dispensed-restocked-tile.component';
import { InventoryTileComponent } from './dashboard/materials-management/inventory-tile/inventory-tile.component';
import { BoxesOrderedReplenishedTileComponent } from './dashboard/materials-management/boxes-ordered-replenished-tile/boxes-ordered-replenished-tile.component';
import { PricingDisclosureComponent } from './footer/pricing-disclosure/pricing-disclosure.component';


@NgModule({
  declarations: [
    LoginComponent,
    GlobalExceptionComponent,
    FooterComponent,
    PrivacyPolicyComponent,
    CustomerSupportComponent,
    LegalNoticeComponent,
    HeaderComponent,
    HomeComponent,
    DashboardComponent,
    UserSetupComponent,
    NotfoundComponent,
    ForgotPwdComponent,
    RequestAccessComponent,
    ResetSuccessComponent,
    SearchResultsComponent,
    SearchBarComponent,
    InfiniteScrollComponent,
    PrivacyPolicyComponent,
    CustomerSupportComponent,
    LegalNoticeComponent,
    NotificationListComponent,
    UnauthorizeComponent,
    ClinicalContainerComponent,
    PreferenceCardOverviewTileComponent,
    InventoryOverviewTileComponent,
    PreferenceCardAccuracyTileComponent,
    OrScanningTileComponent,
    ProductsUsageReturnsTileComponent,
    FinancialOverviewTileComponent,
    TileFilterComponent,
    TileMagnifierComponent,
    StackedBarChartComponent,
    LineChartClinicalComponent,
    TemporaryContainerComponent,
    MaterialsManagementContainerComponent,
    MaterialsManagementTileFilterComponent,
    MaterialsManagementTileMagnifierComponent,
    PickingSummaryTileComponent,
    SummaryTileComponent,
    TodayProgressTrackerTileComponent,
    DispensedRestockedTileComponent,
    InventoryTileComponent,
    BoxesOrderedReplenishedTileComponent,
    PricingDisclosureComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    AlertModule.forRoot(),
    SharedModule,
    MaterialModule,
    OverlayPanelModule,
    ScrollPanelModule
  ],
  exports: [
    GlobalExceptionComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    DashboardComponent,
    NotfoundComponent
  ],
  entryComponents: [GlobalExceptionComponent],
  providers: []
})
export class CoreModule { }
