import { Pipe, PipeTransform } from '@angular/core';
import { NumberArray } from 'd3';

@Pipe({
  name: 'averageDailyUsage'
})
export class AverageDailyUsagePipe implements PipeTransform {

  transform(value: number): any {
    if (value === null || value < 0 ) {
      return 'Pending';
    }
    return value;
  }

}
