import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';
import { AuthService } from '../../app/auth/auth.service';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
  constructor(private authService: AuthService) { }
  // 2020-02-27T11:48:49.740+0000
  transform(value: any, args?: any): any {

    let user = this.authService.getLatestUserData();

    let timeZoneData;
    //currently not using: let timePreferenceData;
    let datePreferenceData;
    let timePreferenceData;

    // these are the format passing to angular core date pipe
    let dateDMY = 'MM/DD/y';
    let dateMDY = 'DD/MM/y';
    let datePipeDMY = 'dd/MM/y';
    let datePipeMDY = 'MM/dd/y';
    let timeF = 'HH:mm';
    let timeH = 'shortTime';
    let time12 ='hh:mm a';
    let time24 ='HH:mm';

    // default time zone
    let dateDefault = 'UTC';

    // format of preference taken from token
    let dateFormat = 'MM/DD/YYYY';
    let timeFormat = '24';

    // arguments recieveing
    let dateArg = 'date';
    let timeArg = 'time';
    let dateTimeArg = 'dateTime';
    let todayArg = 'isToday';
    let yearArg = 'year';
    let dateString = 'dateString';
    let localTimezoneString = 'localTimezone';
    let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    let dateOnly = 'dateOnly';

    let dateToDate = 'dateToDate';

    if (user && value !== (null || undefined) && args !== dateToDate) {
      timeZoneData = user.facilityTimezone || user.facilityTimezone !== (null || undefined) ? user.facilityTimezone : dateDefault;
      if (user.datePreference || user.datePreference !== (null || undefined)) {
        datePreferenceData = user.datePreference === dateFormat ? dateDMY : dateMDY
      } else {
        datePreferenceData = dateDMY;
      }
      if (user.timePreference || user.timePreference !== (null || undefined)) {
        timePreferenceData = user.timePreference === timeFormat ? time24 : time12;
      } else {
        timePreferenceData = time12;
      }
      // currently not using : if (user.timePreference || user.timePreference !== (null || undefined)) {
      // need to fix in future : timePreferenceData = user.timePreference === timeFormat ? timeF : timeH;
      // currently not using : } else {
      // need to fix in future : timePreferenceData = timeH; 
      // currently not using: }
      if (args === dateOnly && (user.datePreference || user.datePreference !== (null || undefined))) {
        datePreferenceData = user.datePreference === dateFormat ? datePipeMDY : datePipeDMY;
      }
      if (args === localTimezoneString) {
        timeZoneData = '';
      }
      if (timeZoneData === 'EST5EDT') {
        if (Date.parse('Jan 01, 1970 00:00:00 EST') == Date.parse('Jan 01, 1970 00:00:00 EDT')) {
          timeZoneData = 'EST';
        } else {
          timeZoneData = 'EDT';
        }
      }
      if (iOS && value && typeof (value) === 'string') {
        value = value.replace('+0000', 'Z');
      } else {
        value = new Date(value).toISOString();
      }

      let date;
      let time;
      let yearDate;
      let dateStringVal;

      date = moment(value).tz('America/New_York').format(datePreferenceData);
      time = moment(value).tz('America/New_York').format(timePreferenceData);
      // time = moment(value).tz('America/New_York').format('hh:mm a');
      // for future reference time = new DatePipe('en-US').transform(value, timePreferenceData, timeZoneData).toLowerCase();
      yearDate = new DatePipe('en-US').transform(value, 'y-dd-MM', timeZoneData);
      dateStringVal = new DatePipe('en-US').transform(value, 'longDate', timeZoneData) + ' ' + (new DatePipe('en-US').transform(value, 'shortTime', timeZoneData)).toLowerCase();

      let dateToday = new Date();
      let todayUTC = dateToday.toISOString();
      let todayDate = new DatePipe('en-US').transform(todayUTC, datePreferenceData, timeZoneData);


      args === dateArg ?
        value = date // dd/mm/yyyy or mm/dd/yyyy
        : args === timeArg ?
          value = time // 03.45 pm or 22.46
          : ((args === dateTimeArg) || (args === localTimezoneString)) ?
            value = date + ' ' + time // dd/mm/yyyy 03.45 pm
            : args === todayArg ?
              todayDate === date ? value = true : value = false // true or false
              : args === yearArg ?
                value = yearDate
                : args === dateString ?
                  value = dateStringVal
                  : args === dateOnly ?
                    value = new DatePipe('en-US').transform(value, datePreferenceData)
                    : value = new DatePipe('en-US').transform(value, dateDMY, dateDefault);

    } else if (dateToDate === 'dateToDate') {
      if ((value !== null && value !== undefined) || value) {
        let dateStr = value.toString();
        let p = dateStr.split(/\D/g);
        if (user && user.datePreference === dateFormat) {
          value = [p[2], p[1], p[0]].join('/');
        } else {
          value = [p[1], p[2], p[0]].join('/');
        }
      }
    } else {
      value = new DatePipe('en-US').transform(value, dateDMY, dateDefault); // year 14-04-2020
    }
    return value;
  }
}
