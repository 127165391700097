import { Component, OnInit } from '@angular/core';
import { PDialogService } from './p-dialog.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-p-dialog',
  templateUrl: './p-dialog.component.html',
})
export class PDialogComponent implements OnInit {
  public showDialog: boolean;
  public errorDialog: object;



  constructor(
    private readonly pDialogService: PDialogService,
    private readonly router: Router) { }

  public ngOnInit(): void {
    this.pDialogService.visibility.subscribe(data => {
      this.showDialog = data;
    });
    this.pDialogService.dialog.subscribe(data => {
      this.errorDialog = data;
    });
  }

  public display(): void {
    console.log("welcome");
    this.pDialogService.hide();
    this.router.navigate(['dashboard']);
  }
}