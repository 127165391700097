import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClinicalService } from '../clinical.service';
import { Router } from '@angular/router';
import { AdamConf } from '@app/app.config';
import { Subscription, timer } from 'rxjs';
import { LoggerService } from '@app/core/logger.service';
import { AuthService } from '@app/auth/auth.service';

@Component({
  selector: 'app-inventory-overview-tile',
  templateUrl: './inventory-overview-tile.component.html',
  styleUrls: ['./inventory-overview-tile.component.scss']
})
export class InventoryOverviewTileComponent implements OnInit, OnDestroy {

  public adamLabels = AdamConf;
  public inventoryOverviewTileData: any;
  private kpiDataSubscription$: Subscription;
  private ticker$: Subscription;

  constructor(
    private readonly clinicalService: ClinicalService,
    private readonly router: Router,
    private readonly logger: LoggerService,
    private readonly authService: AuthService) {
  }

  public ngOnInit(): void {
    this.loadInventoryOverviewTileData();
    this.setAutoRefresh();
  }

  private loadInventoryOverviewTileData(): void {
    this.resetKpiDataSubscription();
    this.kpiDataSubscription$ = this.clinicalService.getClinicalTowerKpis('Inventory Usage Overview').subscribe((resp) => {
      if (resp.responseCode === '2001') {
        resp.responseData.keyValueItems.forEach(element => {
          const routerParams = element.link.substring(element.link.lastIndexOf('/') + 1);
          element.routerParams = routerParams;
        });
        this.inventoryOverviewTileData = resp.responseData.keyValueItems;
      } else {
        this.inventoryOverviewTileData = null;
      }
    });
  }

  private setAutoRefresh(): void {
    const currentUser = this.authService.getCurrentUser();
    if (currentUser.isAutoRefreshEnabled === true) {
      const delayToStartTimer = currentUser.autoRefreshTimeInterval;
      const timerInterval = delayToStartTimer;
      const source = timer(delayToStartTimer, timerInterval);
      this.ticker$ = source.subscribe((val) => {
        this.logger.log('Refreshing Inventory KPI tile data');
        this.loadInventoryOverviewTileData();
      });
    }
  }

  public navigateToProductDetails(params: string): void {
    this.router.navigate(['/insights/inventory/overview', params]);
  }

  public ngOnDestroy(): void {
    this.resetKpiDataSubscription();
    if (this.ticker$) {
      this.ticker$.unsubscribe();
    }
  }

  private resetKpiDataSubscription(): void {
    if (this.kpiDataSubscription$) {
      this.kpiDataSubscription$.unsubscribe();
    }
  }

}
