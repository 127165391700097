import { Component, OnInit, OnDestroy } from '@angular/core';
import { FilterApiResponse } from '../../models/clinical-dashboard-model';
import { ClinicalService } from '../clinical.service';

@Component({
  selector: 'app-clinical-container',
  templateUrl: './clinical-container.component.html',
  styleUrls: ['./clinical-container.component.scss']
})
export class ClinicalContainerComponent implements OnInit, OnDestroy {

  public filterConfig: any;
  public financialOverviewFilterConfig: any = {};
  private getFilterPopupState$: any;
  public isDisplayFilter: any;
  public orScanningFilterConfig: any = {};
  public preferenceCardAccuracyFilterdata: any;
  public preferenceCardFilterConfig: any = {};
  public productUsageFilterConfig: any = {};
  public showMagnifiedViewPopupData: any;

  constructor(private readonly clinicalService: ClinicalService) { }

  public ngOnInit(): void {
    this.isDisplayFilter = false;
    this.showMagnifiedViewPopupData = { isMagnifiedView: false, tile: '' };
    this.clinicalService.getTileDataFilters('Preference Card Accuracy').subscribe((response:FilterApiResponse) => {
      this.clinicalService.setPreferenceCardAccuracyFilter(response.responseData);
      this.preferenceCardFilterConfig = {
        tileName: 'Preference Card Accuracy',
        tileFilters: response.responseData
      };
    });
    this.clinicalService.getTileDataFilters('OR Scanning Accuracy').subscribe((response:FilterApiResponse) => {
      this.clinicalService.setOrScanningFilter(response.responseData);
      this.orScanningFilterConfig = {
        tileName: 'OR Scanning',
        tileFilters: response.responseData
      };
    });
    this.clinicalService.getTileDataFilters('Product%20Usage%20%26%20Returns').subscribe((response:FilterApiResponse) => {
      this.clinicalService.setProductUsageAndReturnsFilter(response.responseData);
      this.productUsageFilterConfig = {
        tileName: 'Product Usage & Returns',
        tileFilters: response.responseData
      };
    });
    this.clinicalService.getTileDataFilters('Financial Overview').subscribe((response:FilterApiResponse) => {
      this.clinicalService.setFinancialOverviewFilter(response.responseData);
      this.financialOverviewFilterConfig = {
        tileName: 'Financial Overview',
        tileFilters: response.responseData
      };
    });
    this.getFilterPopupState$ = this.clinicalService.filterPopupState$.subscribe((event) => {
      this.subscribeToPopupState(event);
    });
  }

  public ngOnDestroy(): void {
    this.clinicalService.setFilterPopupState(null);
    if (this.getFilterPopupState$) {
      this.getFilterPopupState$.unsubscribe();
    }
  }

  public showMagnifiedPopup(event: any): void {
    this.showMagnifiedViewPopupData = event;
  }

  private subscribeToPopupState(event: any): void {
    if (event && event.isDisplayFilter) {
      this.showMagnifiedViewPopupData.tile = '';
      this.isDisplayFilter = event.isDisplayFilter;
      switch (event.tile) {
        case 'Preference Card Accuracy':
          this.filterConfig = this.preferenceCardFilterConfig;
          break;
        case 'OR Scanning Accuracy':
          this.filterConfig = this.orScanningFilterConfig;
          break;
        case 'Product Usage & Returns':
          this.filterConfig = this.productUsageFilterConfig;
          break;
        case 'Financial Overview':
          this.filterConfig = this.financialOverviewFilterConfig;
          break;
      }
    } else {
      this.isDisplayFilter = false;
    }
  }

  public applyFilter(filterData): void {
    this.isDisplayFilter = false;
    switch (filterData.tileName) {
      case 'Preference Card Accuracy':
        this.preferenceCardFilterConfig = filterData;
        this.clinicalService.setPreferenceCardAccuracyFilter(filterData.tileFilters);
        break;
      case 'OR Scanning':
        this.orScanningFilterConfig = filterData;
        this.clinicalService.setOrScanningFilter(filterData.tileFilters);
        break;
      case 'Product Usage & Returns':
        this.productUsageFilterConfig = filterData;
        this.clinicalService.setProductUsageAndReturnsFilter(filterData.tileFilters);
        break;
      case 'Financial Overview':
        this.financialOverviewFilterConfig = filterData;
        this.clinicalService.setFinancialOverviewFilter(filterData.tileFilters);
        break;
    }
    this.clinicalService.setFilterPopupState(null);
  }

}
