import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './core/home/home.component';
import { NotfoundComponent } from './core/notfound/notfound.component';
import { LoginComponent } from './core/login/login.component';
import { ForgotPwdComponent } from './core/forgot-pwd/forgot-pwd.component';
import { ResetpwdComponent } from './core/resetpwd/resetpwd.component';
import { RequestAccessComponent } from './core/request-access/request-access.component';
import { ResetSuccessComponent } from './core/reset-success/reset-success.component';
import { DashboardComponent } from './core/dashboard/dashboard.component';
import { SearchResultsComponent } from './core/search-results/search-results.component';
import { AuthGuard } from './auth/auth.guard';
import { DomainGuard } from './auth/domain.guard';
import { PrivacyPolicyComponent } from './core/footer/privacy-policy/privacy-policy.component';
import { CustomerSupportComponent } from './core/footer/customer-support/customer-support.component';
import { LegalNoticeComponent } from './core/footer/legal-notice/legal-notice.component';
import { NotificationListComponent } from './core/notification/notification-list/notification-list.component';
import { UserSetupComponent } from './core/user-setup/user-setup.component';
import { UnauthorizeComponent } from './core/unauthorize/unauthorize.component';
import { ClinicalContainerComponent } from './core/dashboard/clinical/clinical-container/clinical-container.component';
import { MaterialsManagementContainerComponent } from './core/dashboard/materials-management/materials-management-container/materials-management-container.component';
import { TemporaryContainerComponent } from './core/dashboard/temporary/temporary-container/temporary-container.component';
import { AdamConf } from './app.config';
import { PricingDisclosureComponent } from './core/footer/pricing-disclosure/pricing-disclosure.component';

const routes: Routes = [
  {
    path: '', canActivate: [AuthGuard, DomainGuard], canActivateChild: [AuthGuard],
    children: [
      { path: '', redirectTo: 'external', pathMatch: 'full' },
      { path: 'home', component: HomeComponent, data: { module: 'home' } },
      { path: 'search-results/:searchQuery/:selectedStatus', component: SearchResultsComponent },
      {
        path: '', component: DashboardComponent, children: [
          {
            path: 'dashboard', component: DashboardComponent, data: {
              roles: [AdamConf.role.superAdmin,
              AdamConf.role.adminMaintenance,
              AdamConf.role.adminPeriOp,
              AdamConf.role.circulatingNurse,
              AdamConf.role.hospitalExec,
              AdamConf.role.hospitalIT,
              AdamConf.role.materialsHandler,
              AdamConf.role.materialsManager,
              AdamConf.role.nurseManager,
              AdamConf.role.adminSupplyChain,
              AdamConf.role.materialsAdmin,
              AdamConf.role.clinicalAdmin,]
            }
          },
          {
            path: 'dashboard/materials-management', component: MaterialsManagementContainerComponent, data: {
              roles: [AdamConf.role.superAdmin,
              AdamConf.role.adminMaintenance,
              AdamConf.role.adminSupplyChain,
              AdamConf.role.hospitalIT,
              AdamConf.role.hospitalExec,
              AdamConf.role.materialsHandler,
              AdamConf.role.materialsManager,
              AdamConf.role.materialsAdmin,]
            }
          },
          {
            path: 'dashboard/clinical', component: ClinicalContainerComponent, data: {
              roles: [AdamConf.role.superAdmin,
              AdamConf.role.circulatingNurse,
              AdamConf.role.nurseManager,
              AdamConf.role.hospitalExec,
              AdamConf.role.adminPeriOp,
              AdamConf.role.clinicalAdmin,]
            }
          },
          {
            path: 'dashboard/executive', component: TemporaryContainerComponent, data: {
              roles: [AdamConf.role.superAdmin,
              AdamConf.role.hospitalExec]
            }
          },
          {
            path: 'dashboard/financial', component: TemporaryContainerComponent, data: {
              roles: [AdamConf.role.superAdmin,
              AdamConf.role.hospitalExec]
            }
          },
          {
            path: 'dashboard/operations', component: TemporaryContainerComponent, data: {
              roles: [AdamConf.role.superAdmin,
              AdamConf.role.adminMaintenance,
              AdamConf.role.adminPeriOp,
              AdamConf.role.hospitalExec,
              AdamConf.role.materialsManager,
              AdamConf.role.nurseManager,
              AdamConf.role.adminSupplyChain]
            }
          }
        ]
      },
      { path: 'forgot-password', component: ForgotPwdComponent, data: { module: 'forgot-password' } },
      { path: 'reset-password', component: ResetpwdComponent },
      { path: 'reset-success', component: ResetSuccessComponent },
      { path: 'complete-registration/:username/:tmppwd/:token/:fname/:lname', component: UserSetupComponent },
      { path: 'complete-registration-topics/:username/:tmppwd/:token/:fname/:lname', component: UserSetupComponent },
      { path: 'complete-registration-notifications/:username/:tmppwd/:token/:fname/:lname', component: UserSetupComponent },
      { path: 'complete-registration-login/:username/:tmppwd/:token/:fname/:lname', component: UserSetupComponent },
      // data:{module:'confirm-password'}
      { path: 'reset-password/:username/:code/:token', component: ResetpwdComponent, data: { module: 'confirm-password' } },
      { path: 'password-expired/:username', component: ResetpwdComponent },
      { path: 'login', component: LoginComponent, data: { module: 'login' } },
      { path: 'request-access', component: RequestAccessComponent, data: { module: 'request-access' } },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'legal-notice', component: LegalNoticeComponent },
      { path: 'pricing-disclosure', component: PricingDisclosureComponent },
      { path: 'user-setup', component: UserSetupComponent },
      { path: 'customer-support', component: CustomerSupportComponent },
      { path: 'notifications/list', component: NotificationListComponent },
      { path: 'institutions', loadChildren: () => import('./institutions/institutions.module').then(m => m.InstitutionsModule) },
      { path: 'inventories', loadChildren: () => import('./inventories/inventories.module').then(m => m.InventoriesModule) },
      { path: 'control-tower', loadChildren: () => import('./control-tower/control-tower.module').then(m => m.ControlTowerModule) },
      { path: 'insights', loadChildren: () => import('./insights/insights.module').then(m => m.InsightsModule) },
      { path: 'pick-request', loadChildren: () => import('./pick-request/pick-request.module').then(m => m.PickRequestModule) },
      //{ path : 'assessment-tool', loadChildren: () => import('./assessment-tool/assessment-tool.module').then(m => m.AssessmentToolModule) },
      { path: 'user-management', loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule) },
      // { path : 'user-management/account-settings/topics', loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule) },
      { path: 'system-management', loadChildren: () => import('./system-management/system-management.module').then(m => m.SystemManagementModule) },
      { path: 'ops', loadChildren: () => import('./ops/ops.module').then(m => m.OpsModule) },
      { path: 'unauthorized', component: UnauthorizeComponent }, 
      { path: '**', component: NotfoundComponent }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', // Add options right here
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
