import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { PrimengModule } from './primeng/primeng.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgxbootstrapModule } from './ngxbootstrap/ngxbootstrap.module';
import { MaterialbModule } from './materialb/materialb.module';
import { TopicsComponent } from '../user-management/topics/topics.component';
import { NotificationComponent } from '../user-management/notification/notification.component';
import { ResetpwdComponent } from '../core/resetpwd/resetpwd.component';
import { PrintComponent } from './print/print.component';
import { CustomDatePipe } from './customDate.pipe';
import { CustomPadPipe } from './pipes/customPad.pipe';
import { QRCodeModule } from 'angularx-qrcode';
import { FormatDatePipe } from './formatDate.pipe';
import { LineGraphComponent } from './components/line-graph/line-graph.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { PickRequestPipe } from './pipes/pick-request.pipe';
import { GroupedBarChartComponent } from './components/grouped-bar-chart/grouped-bar-chart.component';
import { CaptchaComponent } from './components/captcha/captcha.component';
import { AverageDailyUsagePipe } from './pipes/average-daily-usage.pipe';

@NgModule({
   declarations: [
      ResetpwdComponent,
      TopicsComponent,
      NotificationComponent,
      PrintComponent,
      CustomDatePipe,
      CustomPadPipe,
      FormatDatePipe,
      LineGraphComponent,
      PieChartComponent,
      PickRequestPipe,
      GroupedBarChartComponent,
      CaptchaComponent,
      AverageDailyUsagePipe
   ],
   imports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      PrimengModule,
      MultiSelectModule,
      NgxbootstrapModule,
      MaterialbModule,
      QRCodeModule,
      RecaptchaModule
   ],
   exports: [
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      PrimengModule,
      MultiSelectModule,
      NgxbootstrapModule,
      ReactiveFormsModule,
      MaterialbModule,
      TopicsComponent,
      NotificationComponent,
      ResetpwdComponent,
      PrintComponent,
      CustomDatePipe,
      CustomPadPipe,
      FormatDatePipe,
      LineGraphComponent,
      PieChartComponent,
      PickRequestPipe,
      GroupedBarChartComponent,
      CaptchaComponent,
      AverageDailyUsagePipe
   ]
})
export class SharedModule { }
