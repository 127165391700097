import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AdamConf } from './app.config';
import { AuthService } from './auth/auth.service';
import { GlobalSpinnerComponent } from './core/global-spinner/global-spinner.component';
import { OpsAuthService } from './ops/opsAuth.service';
import { StorageService } from './core/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  activeHeader = false;
  activeFooter = false;
  public globalSpinnerComponent = GlobalSpinnerComponent;
  customObject = 'hospital';
  greyBgPages = [
    'dashboard',
    'replenishment/select-unit',
    'expire/select-unit',
    'ops',
    'inventories/inventory-levels',
    'recall/select-unit',
    'select-unit',
    'expire/select-unit-mobile'
  ];
  greybg = false;
  isOpsHeader = false;
  loaderExclusions = AdamConf.LOADER_EXCLUSIONS;
  noHeaderPages = [
    'edit',
    'review',
    'planogram',
    'print-unit',
    'hand-held-scanner/scanner',
    'expire-product-mobile',
    'assessment-tool',
    '/expire/select-unit-mobile',
    '/ops/configurator-summary',
    '/ops/configure-tool'
  ];
  minimalHeaderPages = [
    'login',
    'forgot-password',
    'reset-password',
    'reset-success',
    'complete-registration',
    'complete-registration-topics',
    'complete-registration-notifications',
    'complete-registration-login',
    'request-access',
    'reset-password',
    'assessment-tool',
    'password-expired',
    'ops',
    'hand-held-scanner/select-unit',
    'expire/select-unit-mobile'
  ];
  searchCounter: number;
  minimalHeader: boolean;
  showLogo: boolean;
  authenticated: boolean;
  opsAuthenticated: boolean;
  searchNav: boolean;
  greyBgCounter;
  printStyle = false;
  currentModule = '';
  minimalHeaderPagesCounter;

  constructor(
    private router: Router,
    private authService: AuthService,
    private opsAuthService: OpsAuthService,
    private storageService: StorageService) {
    this.authService.currentUser.subscribe(data => {
      if (data) {
        this.authenticated = true;
      } else {
        this.authenticated = false;
      }
    });
    this.opsAuthService.currentUser.subscribe(data => {
      if (data) {
        this.opsAuthenticated = true;
      } else {
        this.opsAuthenticated = false;
      }
    });
  }

  ngOnInit() {
    if (this.authService.getCurrentUser()) {
      this.authenticated = true;
    } else {
      this.authenticated = false;
    }
    if (this.opsAuthService.getOpsCurrentUser()) {
      this.opsAuthenticated = true;
    } else {
      this.opsAuthenticated = false;
    }

    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.searchCounter = 0;
        this.minimalHeaderPagesCounter = 0;
        if (e.url.indexOf('print-unit') !== -1) {
          this.printStyle = true;
        }
        this.noHeaderPages.forEach(element => {
          if (e.url.indexOf(element) !== -1) {
            ++this.searchCounter;
          }
        });
        if (this.searchCounter > 0) {
          this.activeHeader = false;
          this.activeFooter = false;
        } else {
          this.activeHeader = true;
          this.activeFooter = true;
        }

        // Set separate flag for Ops module to hide all header menu options.
        this.isOpsHeader = e.url.indexOf('ops') !== -1 || e.url.indexOf('unauthorized') !== -1;
        this.minimalHeaderPages.forEach(element => {
          if (e.url.indexOf(element) !== -1) {
            ++this.minimalHeaderPagesCounter;
          }
        });
        if (this.minimalHeaderPagesCounter > 0) {
          this.minimalHeader = true;
        } else {
          this.minimalHeader = false;
        }

        if (e.url.indexOf('/home') !== -1) {
          this.showLogo = false;
        } else {
          this.showLogo = true;
        }

        this.greyBgCounter = 0;
        this.greyBgPages.forEach(element => {
          if (element === 'ops') {
            if (e.url === '/ops' || e.url === '/ops/dashboard') {
              ++this.greyBgCounter;
            }
          } else {
            if (e.url.indexOf(element) !== -1) {
              ++this.greyBgCounter;
            }
          }
        });
        if (this.greyBgCounter > 0) {
          this.greybg = true;
        } else {
          this.greybg = false;
        }

        if (e.url.indexOf('/inventories') !== -1 || e.url.indexOf('/pick-request') !== -1) {
          this.currentModule = 'productMangement';
        } else if (e.url.indexOf('/system-management') !== -1) {
          this.currentModule = 'systemManagement';
        } else if (e.url.indexOf('/user-management') !== -1) {
          this.currentModule = 'userManagement';
        } else if (e.url.indexOf('/notifications/list') !== -1) {
          this.currentModule = 'notifications';
        } else {
          this.currentModule = '';
        }      
        this.storageService.setSessionItem('user-navigation', e.url);  
      }     
    });
  }

  updateSearchNav(searchNavVal) {
    this.searchNav = searchNavVal;
  }

  selectedOption(option) {
    this.customObject = option;
  }

}
