import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';
import { AuthService } from '../../app/auth/auth.service';
import { userInfo } from 'os';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  constructor(private authService: AuthService) { }
  // 2020-02-27T11:48:49.740+0000
  transform(value: any, args?: any): any {
    let user = this.authService.getLatestUserData();
    let formattedDate;
    if (user && user.datePreference !== null) {
        formattedDate = new DatePipe('en-US').transform(value,this.checkFormat( args,user.datePreference));
    }
    return formattedDate;
  }
  
  checkFormat(args:string, datePreference: string){
    switch(datePreference){
      case 'MM/DD/YYYY': {
        return args === 'yearShort' ? 'MM/dd/yy' : 'MM/dd/yyyy';
      }
      case 'DD/MM/YYYY' : {
        return args === 'yearShort' ? 'dd/MM/yy' : 'dd/MM/yyyy';
      }
    }
  }
}
