import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AdamConf } from '@app/app.config';
import { AuthService } from '@app/auth/auth.service';
import {
  ChartConfiguration,
  LineConfig,
} from '@app/shared/models/line-chart.model';
import { Subscription, timer } from 'rxjs';
import { TileFilterInfo } from '../../models/clinical-dashboard-model';
import {
  MagnifiedViewModel,
  TileDataApiResponse,
  TileResponseData,
  FilterStateConfig
} from '../../models/materials-management-dashboard.model';
import { LoggerService } from '@app/core/logger.service';
import { MaterialsManagementService } from '../materials-management.service';

@Component({
  selector: 'app-core-dashboard-materials-management-dispensed-restocked-tile',
  templateUrl: './dispensed-restocked-tile.component.html',
  styleUrls: ['./dispensed-restocked-tile.component.scss'],
})
export class DispensedRestockedTileComponent implements OnDestroy, OnInit {
  // Variables
  public adamLabels = AdamConf;
  public isDisplayFilter = false;
  public selectedOptions = [];
  public lineConfig: LineConfig[] = [
    {
      name: 'Dispensed',
      color: '#12224D',
      lineType: 'line'
    },
    {
      name: 'Restocked',
      color: '#888B8D',
      lineType: 'line'
    }
  ];
  public filterConfig: FilterStateConfig;
  private filterSubscription$: Subscription;
  private dataSubscription$: Subscription;
  private ticker$: Subscription;
  public dispensedRestockParams: TileFilterInfo;
  public chartMetaData: ChartConfiguration = {
    type: 'dispensed-restocked',
    xAxis: 'date',
    yAxis: '',
    margin: {
      top: 20,
      right: 20,
      bottom: 50,
      left: 45,
    },
    gridLines: {
      xAxis: true,
      yAxis: false
    }
  };
  public lineChartData: TileResponseData;

  // Input & Output
  @Input() showMagnifiedViewPopupData: MagnifiedViewModel;
  @Output() showMagnifiedPopup = new EventEmitter<MagnifiedViewModel>();

  constructor(
    private materialManagementService: MaterialsManagementService,
    private readonly router: Router,
    private readonly logger: LoggerService,
    private readonly authService: AuthService
  ) { }

  ngOnInit(): void {
    this.loadFilterData();
    this.setAutoRefresh();
  }

  private setAutoRefresh(): void {
    const currentUser = this.authService.getCurrentUser();
    if (currentUser.isAutoRefreshEnabled === true && this.showMagnifiedViewPopupData.isMagnifiedView !== true) {
      const delayToStartTimer = currentUser.autoRefreshTimeInterval;
      const timerInterval = delayToStartTimer;
      const source = timer(delayToStartTimer, timerInterval);
      this.ticker$ = source.subscribe((val) => {
        this.logger.log('Refreshing Dispensed and Restocked tile data');
        this.loadFilterData();
      });
    }
  }

  public displayFilter(): void {
    this.isDisplayFilter = true;
    this.filterConfig = {
      isDisplayFilter: this.isDisplayFilter,
      tile: this.adamLabels.materialManagement_module.dispensedRestockedLabel
    };
    this.materialManagementService.setFilterPopupState(this.filterConfig);
    this.showMagnifiedViewPopupData.isMagnifiedView = false;
  }

  public showMagnifiedView(): void {
    this.showMagnifiedViewPopupData.isMagnifiedView = (this.showMagnifiedViewPopupData.isMagnifiedView === true) ? false : true;
    this.showMagnifiedViewPopupData.tile =
      (this.showMagnifiedViewPopupData.isMagnifiedView === true) ? this.adamLabels.materialManagement_module.dispensedRestockedLabel : '';
    this.showMagnifiedPopup.emit(this.showMagnifiedViewPopupData);
  }

  private loadFilterData(): void {
    this.resetDispensedTileSubscriptions();
    this.filterSubscription$ = this.materialManagementService.dispensedRestockedTileFilterState$
      .subscribe((data) => {
        if (data) {
          this.dispensedRestockParams = {
            tileName: this.adamLabels.materialManagement_module.dispensedRestockedTileName,
            tileFilters: data
          };
          if (this.dispensedRestockParams && this.dispensedRestockParams.tileFilters.length > 0) {
            const selectedFilters = this.dispensedRestockParams.tileFilters.filter(item =>
              item.selectedValues && item.selectedValues.length > 0);
            this.selectedOptions = [];
            selectedFilters.forEach(item => {
              if (item.selectedValues.length > 0 && item.fieldName !== this.adamLabels.materialManagement_module.customDateRange) {
                item.selectedValues.forEach(ele => this.selectedOptions.push(ele));
              }
            });
          }
          this.getChartData();
        }
      });
  }

  private getChartData(): void {
    if (this.dataSubscription$) {
      this.dataSubscription$.unsubscribe();
    }
    this.dataSubscription$ =
      this.materialManagementService.getTileData(this.dispensedRestockParams).subscribe((response: TileDataApiResponse) => {
        if (response.responseCode === '2001' && response && response.responseData) {
          this.lineChartData = response.responseData;
        }
      });
  }

  private resetDispensedTileSubscriptions(): void {
    if (this.dataSubscription$) {
      this.dataSubscription$.unsubscribe();
    }
    if (this.filterSubscription$) {
      this.filterSubscription$.unsubscribe();
    }
  }

  public ngOnDestroy(): void {
    this.resetDispensedTileSubscriptions();
    if (this.ticker$) {
      this.ticker$.unsubscribe();
    }
  }

  public navigateToPreferenceCardOverview(): void {
    this.router.navigate(['/control-tower/dispensed-restocked']);
  }

}
