import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdamConf } from '@app/app.config';
import { Subscription } from 'rxjs';
import { MagnifiedViewModel, FilterConfig, FilterStateConfig, TileFilter } from '../../models/materials-management-dashboard.model';
import { MaterialsManagementService } from '../materials-management.service';
import { StorageService } from 'src/app/core/storage.service';

@Component({
  selector: 'app-core-dashboard-materials-management-container',
  templateUrl: './materials-management-container.component.html',
  styleUrls: ['./materials-management-container.component.scss']
})
export class MaterialsManagementContainerComponent implements OnInit, OnDestroy {
  public displayRestocking: string;
  public displayReplenishment: string;
  public displayOrdering: string;
  public materialManagementTiles: string[];
  public tileFilterSubscriptions$: Subscription[];
  public showMagnifiedViewPopupData: MagnifiedViewModel;
  public dispensedRestockFilterConfig: FilterConfig;
  public inventoryFilterConfig: FilterConfig;
  public filterConfig: FilterConfig;
  public isDisplayFilter: boolean;
  public adamLabels = AdamConf;
  public getFilterPopupState$: Subscription;
  public boxesOrderedReplenishedFilterConfig: FilterConfig;
  public displayTransfers: string;

  constructor(private materialManagementService: MaterialsManagementService,
    private readonly storageService: StorageService, ) { }

  public ngOnInit(): void {
    this.displayRestocking = 'Restocking';
    this.displayReplenishment = 'Replenishment';
    this.displayOrdering = 'Ordering';
    this.displayTransfers = 'Transfers';
    this.materialManagementTiles = [
      this.adamLabels.materialManagement_module.inventoryTileName,
      this.adamLabels.materialManagement_module.boxesOrderedReplenishedTileName,
      this.adamLabels.materialManagement_module.dispensedRestockedTileName
    ];
    this.isDisplayFilter = false;
    this.showMagnifiedViewPopupData = { isMagnifiedView: false, tile: '' };
    this.loadDefaultFilters();
  }

  private loadDefaultFilters() {
    let subscription = null;
    this.tileFilterSubscriptions$ = [];
    this.materialManagementTiles.forEach((materialManagementTileName) => {
      subscription =
        this.materialManagementService.getTileDataFilters(materialManagementTileName).subscribe((response) => {
          this.materialManagementService.setTileDataFilters(materialManagementTileName, response.responseData);
          switch (materialManagementTileName) {
            case this.adamLabels.materialManagement_module.dispensedRestockedTileName:
              this.dispensedRestockFilterConfig = this.setFilterConfigurations(materialManagementTileName, response.responseData);
              break;
            case this.adamLabels.materialManagement_module.inventoryTileName:
              this.inventoryFilterConfig = this.setFilterConfigurations(materialManagementTileName, response.responseData);
              break;
            case this.adamLabels.materialManagement_module.boxesOrderedReplenishedTileName:
              this.boxesOrderedReplenishedFilterConfig = this.setFilterConfigurations(materialManagementTileName, response.responseData);
              this.storageService.setItem('boxTileFilter', JSON.stringify(response.responseData));
              break;
          }
        });
      this.tileFilterSubscriptions$.push(subscription);
    });
    this.getFilterPopupState$ = this.materialManagementService.filterPopupState$.subscribe((event: FilterStateConfig) => {
      this.subscribeToPopupState(event);
    });
  }

  private setFilterConfigurations(tile: string, filterData: TileFilter[]): FilterConfig {
    return {
      tileName: tile,
      tileFilters: filterData
    };
  }

  private subscribeToPopupState(event: FilterStateConfig): void {
    if (event && event.isDisplayFilter) {
      this.showMagnifiedViewPopupData.tile = '';
      this.isDisplayFilter = event.isDisplayFilter;
      switch (event.tile) {
        case this.adamLabels.materialManagement_module.dispensedRestockedLabel:
          this.filterConfig = this.dispensedRestockFilterConfig;
          break;
        case this.adamLabels.materialManagement_module.inventoryTileName:
          this.filterConfig = this.inventoryFilterConfig;
          break;
        case this.adamLabels.materialManagement_module.boxesOrderedReplenishedTileName:
          this.filterConfig = this.boxesOrderedReplenishedFilterConfig;
          break;
      }
    } else {
      this.isDisplayFilter = false;
    }
  }

  public applyFilter(filterData: FilterConfig): void {
    this.isDisplayFilter = false;
    this.materialManagementService.setTileDataFilters(filterData.tileName, filterData.tileFilters);
    this.saveFilterState(filterData);
    this.materialManagementService.setFilterPopupState(null);
  }

  private saveFilterState(filterData: FilterConfig): void {// for retaining the applied filter
    switch (filterData.tileName) {
      case this.adamLabels.materialManagement_module.dispensedRestockedTileName:
        this.dispensedRestockFilterConfig = filterData; break;
      case this.adamLabels.materialManagement_module.inventoryTileName:
        this.inventoryFilterConfig = filterData; break;
      case this.adamLabels.materialManagement_module.boxesOrderedReplenishedTileName:
        this.boxesOrderedReplenishedFilterConfig = filterData; break;
    }
  }

  public showMagnifiedPopup(event: MagnifiedViewModel): void {
    this.showMagnifiedViewPopupData = event;
  }

  public ngOnDestroy(): void {
    if (this.getFilterPopupState$) {
      this.getFilterPopupState$.unsubscribe();
    }
    if (this.tileFilterSubscriptions$.length > 0) {
      while (this.tileFilterSubscriptions$.length > 0) {
        const subscription = this.tileFilterSubscriptions$.pop();
        subscription.unsubscribe();
      }
    }
  }

}
