import { Component, ViewChild, ElementRef, Input, OnChanges } from '@angular/core';
import { SharedConst } from '@app/shared/const/shared-const';
import { ChartConfiguration, ChartData, MousePosition, ChartRawData, LineConfig } from '@app/shared/models/line-chart.model';
import * as d3 from 'd3';

@Component({
  selector: 'app-common-line-graph',
  templateUrl: './line-graph.component.html',
  styleUrls: ['./line-graph.component.scss']
})
export class LineGraphComponent implements OnChanges {
  sharedConst = SharedConst;

  @Input() lineChartData: ChartData;
  @Input() chartMetaData: ChartConfiguration;
  @Input() lineConfig: LineConfig[];

  @ViewChild('lineChartContainer', { static: false })
  private lineChartContainer: ElementRef;

  constructor() {
    // constructor
  }

  public ngOnChanges(): void {
    if (this.lineChartData && this.lineChartData.categories) {
      this.lineChartData.categories.push(null);
      this.initChart();
    }
  }

  private initChart(): void {
    d3.select(this.lineChartContainer.nativeElement).selectAll('svg').remove();
    const width = this.lineChartContainer.nativeElement.offsetWidth - this.chartMetaData.margin.left - this.chartMetaData.margin.right;
    const height = this.lineChartContainer.nativeElement.offsetHeight - this.chartMetaData.margin.top - this.chartMetaData.margin.bottom;

    const x = d3.scale
      .ordinal()
      .domain(this.lineChartData.categories)
      .rangePoints([0, width]);

    const y = d3.scale.linear()
      .range([height, 0]);

    const xAxis = d3.svg.axis()
      .scale(x)
      .orient('bottom');

    const yAxis = d3.svg.axis()
      .scale(y)
      .orient('left')
      .tickFormat((d) => {
        if (this.chartMetaData.yAxis === 'percentage') {
          return d3.format('.1')(d) + '%';
        } else if (this.chartMetaData.yAxis === 'currency') {
          return '$' + d3.format('s')(d);
        } else {
          return d3.format('.1')(d);
        }
      });

    const line = d3.svg.line()
      .defined((d) => d.count !== null)
      .interpolate('monotone')
      .x((d) => x(d.month))
      .y((d) => y(d.count));

    const svg = d3.select(this.lineChartContainer.nativeElement)
      .append('svg')
      .attr('width', width + this.chartMetaData.margin.left + this.chartMetaData.margin.right)
      .attr('height', height + this.chartMetaData.margin.top + this.chartMetaData.margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + this.chartMetaData.margin.left + ',' + this.chartMetaData.margin.top + ')');

    // Format data
    const graphLines = this.lineChartData.dataSeriesCollection.map((d) => {
      const category = d.legend;
      const lineDetails = this.lineConfig.filter(item => item.name === d.legend);
      let i = 0;
      const dataPoints = d.dataPoints.map((dataPiont) => {
        return {
          month: this.lineChartData.categories[i++],
          count: dataPiont.value,
          tooltipData: dataPiont.toolTipInfo,
          legend: category
        };
      });
      return {
        name: category,
        values: dataPoints,
        color: lineDetails[0].color,
        lineType: lineDetails[0].lineType
      };
    });

    x.domain(this.lineChartData.categories);

    if (this.chartMetaData.yAxis === 'percentage') {
      y.domain([0, 100]);
    } else {
      y.domain([
        d3.min(graphLines, (c) => {
          return d3.min(c.values, (v) => v.count);
        }),
        d3.max(graphLines, (c) => {
          return d3.max(c.values, (v) => v.count);
        })
      ]);
    }



    // Render line chart
    // Draw X axis
    svg.append('g')
      .attr('class', 'x axis xAxis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis)
      .select('path')
      .style('stroke', '#d0cfcd')
      .style('fill', 'none')
      .style('stroke-width', 1)
      .style('shape-rendering', 'crispEdges');

    // Draw Y axis
    svg.append('g')
      .attr('class', 'y axis yAxis')
      .call(yAxis)
      .select('path')
      .style('stroke', '#d0cfcd')
      .style('fill', 'none')
      .style('stroke-width', 1)
      .style('shape-rendering', 'crispEdges')
      .style('stroke-dasharray', '2');

    // Draw Grid lines - X axis
    if (this.chartMetaData.gridLines.xAxis) {
      svg.selectAll('g.xAxis g.tick')
        .append('line')
        .attr('class', 'gridline x')
        .attr('x1', 0)
        .attr('y1', -height)
        .attr('x2', 0)
        .attr('y2', 0)
        .attr('stroke-width', 1)
        .style('opacity', '1')
        .style('stroke', '#d0cfcd')
        .style('stroke-dasharray', '2');
    }

    // Draw Grid lines - Y axis
    if (this.chartMetaData.gridLines.yAxis) {
      svg.selectAll('g.yAxis g.tick')
        .append('line')
        .attr('class', 'gridline y')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', width)
        .attr('y2', 0)
        .attr('stroke-width', 1)
        .style('opacity', '1')
        .style('stroke', '#d0cfcd')
        .style('stroke-dasharray', '2');
    }
    // Add styles to X axis ticks
    svg.selectAll('g.xAxis g.tick text')
      .style('text-anchor', 'end')
      .style('font-size', '10px')
      .style('font-family', 'Noto Sans Regular')
      .attr('transform', 'translate(' + (width / this.lineChartData.categories.length) / 2 + ', 0),rotate(-45)');

    // Add styles to Y axis ticks
    svg.selectAll('g.yAxis g.tick text')
      .style('font-size', '8px')
      .style('font-family', 'Noto Sans Regular')
      .style('text-transform', 'uppercase');

    // Draw Plot Lines
    const graphLine = svg.selectAll('.chart-line')
      .data(graphLines)
      .enter()
      .append('g')
      .attr('class', 'chart-line');

    graphLine.append('path')
      .attr('class', (d) => d.lineType === this.sharedConst.LINE_TYPE.DASHED ? 'dashed-line' : 'line')
      .attr('d', (d) => line(d.values))
      .attr('transform', 'translate(' + (width / this.lineChartData.categories.length + 1) + ', 0)')
      .style('stroke', (d) => this.resolveLineColor(d))
      .style('stroke-width', '1px')
      .style('stroke-dasharray', (d) => d.lineType === this.sharedConst.LINE_TYPE.DASHED ? 5 : 0)
      .style('fill', 'none');

    // Add circles for each plot
    this.appendCircle(graphLine, x, y, width);
  }

  private appendCircle(graphLine: any, x: any, y: any, width: any): void {
    graphLine.append('g').selectAll('circle')
      .data((d) => d.values)
      .enter()
      .append('circle')
      .attr('r', (d) => d.count === null ? 0 : 4)
      .attr('cx', (d) => x(d.month) )
      .attr('cy', (d) => y(d.count))
      .attr('fill', '#FFF')
      .attr('stroke', (d) => this.resolveLineColor(d))
      .attr('transform', 'translate(' + (width / this.lineChartData.categories.length + 1) + ', 0)')
      .on('mouseover', (d) => {
        const div = d3.select('body').append('div')
          .attr('class', 'tooltip-chart-line')
          .style('opacity', 0);
        div.transition()
          .duration(200)
          .style('opacity', .9);
        div.html(this.getTooltipContent(d))
          .style('left', () => {
            const pos = this.positionTooltip(
              {
                x: d3.event.pageX,
                y: d3.event.pageY
              });
            return (pos.left + 10) + 'px';
          })
          .style('top', (d3.event.pageY - 28) + 'px')
          .style('z-index', '10001');
      })
      .on('mouseout', (d) => {
        const tooltip = document.getElementsByClassName('tooltip-chart-line');
        while (tooltip[0]) {
          tooltip[0].parentNode.removeChild(tooltip[0]);
        }
        const div = d3.select('tooltip-chart-line');
        div.transition()
          .duration(500)
          .style('opacity', 0);
      });
  }

  private positionTooltip(mouse): MousePosition {
    const box = document.querySelector('.graph-table');
    if (box) {
      const style = getComputedStyle(box);
      const toolwidth = parseInt(style.width);
      // Distance of element from the right edge of viewport
      if (window.innerWidth - (mouse.x + toolwidth) < 100) { // If tooltip exceeds the X coordinate of viewport
        mouse.x = mouse.x - toolwidth - 30;
      }
      return {
        top: mouse.y,
        left: mouse.x
      };
    }

  }

  private getTooltipContent(d): string {
    let tooltipContent = '';
    tooltipContent = tooltipContent + '<table class="graph-table">';
    if (d.tooltipData) {
      d.tooltipData.forEach(element => {
        tooltipContent += `<tr><td class="title">${element.label}:</td><td class="data-value"> ${element.value} </td></tr>`;
      });
    }
    tooltipContent = tooltipContent + '</table>';
    return tooltipContent;
  }

  private resolveLineColor(data: ChartRawData): string {
    if (data.color) {
      return data.color;
    } else {
      const lineDetails = this.lineConfig.filter(item => item.name === data.legend);
      return lineDetails[0].color;
    }
  }

}
