import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { OpsAuthService } from 'src/app/ops/opsAuth.service';
import { UtilityService } from 'src/app/shared/utility.service';
import { NotificationService } from '../notification/notification.service';
import { StorageService } from '../storage.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import * as _ from 'lodash';
import { CommonApiResponse, MenuFeaturesInfo, MenuListInfo, NotificationApiResponse, } from '../models/header-model';
import { AdamConf } from '@app/app.config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  navbarOpen = false;
  notificationTrayExtended = false;
  hamburgerOpen = false;
  opsMenu: boolean;
  userPermission: any = [];
  notificationCount: any = 0;
  notifications: any = [];
  mapOfAlerts: any = [];
  backEndValidationError = false;
  applicationError = false;
  notificationSubscription: Subscription;
  toggleSearchBar = false;
  notificationCategories: any;
  menuItems: MenuFeaturesInfo[] = [];
  systemAlerts: any = [];
  isPmMenuClicked = false;
  isSmMenuClicked = false;
  notificationBarContent: any;
  productManagementMenuItems: any = [];
  systemManagementMenuItems: any = [];
  @Input('activeHeader') activeHeader;
  @Input('minimalHeader') minimalHeader;
  @Input('authenticated') authenticated;
  @Input('opsAuthenticated') opsAuthenticated;
  @Input('showLogo') showLogo;
  @Input('isOpsHeader') isOpsHeader;
  @Input('currentModule') currentModule;

  @ViewChild('notificationTray', { static: false }) private notificationTray: ElementRef;
  @ViewChild('op', { static: false }) op: OverlayPanel;
  @ViewChild('opsm', { static: false }) opsm: OverlayPanel;
  @Output('searchNavToggled') searchNavToggled: EventEmitter<any> = new EventEmitter<any>();
  isMobileFlag: any;
  adam_labels: any;
  showMultitenanceError = false;
  hideQlikDashboards = false;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly notificationService: NotificationService,
    private readonly eRef: ElementRef,
    private readonly utilityService: UtilityService,
    private readonly opsAuthService: OpsAuthService,
    private readonly storageService: StorageService
  ) { }

  @HostListener('document:click', ['$event']) clickoutsideNotification(event) {
    if (this.eRef.nativeElement.querySelector('.notListDropdown') &&
      !this.eRef.nativeElement.querySelector('.notListDropdown').contains(event.target)) {
      this.navbarOpen = false;
    }
  }

  ngOnInit() {
    this.adam_labels = AdamConf;
    this.getMenuItems();
    this.isMobileFlag = this.utilityService.isMobile();
    this.authService.userPermissions$.subscribe(userData => {
      this.userPermission = userData;
    });

    let currentUserRole;
    if (this.authService.getCurrentUser()) {
      currentUserRole = this.authService.getCurrentUser().role.toLowerCase();
    }

    
    if (AdamConf.HideQlikDashboardRoles.indexOf(currentUserRole) === -1) {
      this.hideQlikDashboards = false;
    } else {
      this.hideQlikDashboards = true;
    }

    this.notificationSubscription = this.authService.notification.subscribe((respStatus: string) => {
      if (respStatus === 'Update') {
        this.getNotification();
      } else if (respStatus === 'loginUpdate') {
        this.notificationCount = 0;
        this.notifications = [];
        this.getNotification();
      }
    });

    if (this.authenticated && !this.isOpsHeader) {
      this.getNotification();
    }
    if (sessionStorage.getItem('ops-menu') === 'true') {
      this.opsMenu = true;
    }
   
  }

  getMenuItems() {
    // subscribing to menuList item for triggering menu changes.
    this.authService.menuList$.subscribe((menuItems:MenuListInfo) => {
      this.menuItems = menuItems ? menuItems.features : [];
      this.menuItems.forEach(menu => {
        menu.subMenus.forEach(subMenu => {
          if (subMenu.category === this.adam_labels.NAV_MENU_ALERT_FIELD) {
            subMenu['type'] = 'alert';
            subMenu.subMenuNames = [];
          }
        });
      });
      this.menuItems = _.sortBy(this.menuItems, 'menuOrder');
    });
  }
  

  showMenu(menu:MenuFeaturesInfo, event?) {
   
    this.menuItems.forEach(element => {
      element.clicked = false;
    });
    if (menu.menuName) {
      menu.clicked = true;
      menu.subMenus = _.sortBy(menu.subMenus, 'categoryOrder');
      menu.subMenuList = [];
      let result = this.groupBy(menu.subMenus, 'column')
      Object.keys(result).forEach(element => {
        menu.subMenuList.push(result[element]);
      });
      this.isPmMenuClicked = true;
      setTimeout(() => {
        this.op.toggle(event);
      }, 0);
      event.stopPropagation();
    } else {
      this.isPmMenuClicked = false;
    }
  }

  //grouping submenu items to display in columns
  groupBy(list, field) {
    let grouped = {};
    list.forEach(element => {
      if (element.subMenuNames && (!element.type || element.type !== 'alert')) {
        element.subMenuNames = _.sortBy(element.subMenuNames, 'submenuOrder');
      }
      if (element.subMenuNames && element.subMenuNames.length) {
        if (Object.keys(grouped).includes(element[field])) {
          grouped[element[field]].push(element);
        } else {
          grouped[element[field]] = [];
          grouped[element[field]].push(element);
        }
      }
    });
    return grouped;
  }
  navigateAndClose(link: string, event: Event) {
    event.preventDefault(); 
    this.hamburgerOpen = false;
    if (this.op) {
        this.op.hide(); 
    }
    this.router.navigate([link]).then(() => {
    }).catch(err => {  
    });
    event.stopPropagation();
}

  chkAuth(menu, key) {
    let returnVal = false;
    // for headings
    if (this.userPermission) {
      if (!key && menu) {
        if (this.userPermission[menu] && this.userPermission[menu].length > 0) {
          returnVal = true;
        }
      } else {
        if (this.userPermission[menu] && this.userPermission[menu].indexOf(key) !== -1) {
          returnVal = true;
        }
      }
    }
    return returnVal;
  }

  navigateToUserHome() {
    if (this.isMobileFlag === true) {
      this.router.navigate(['inventories/hand-held-scanner/replenishment-summary']);
    } else {
      const userHomeData = JSON.parse(this.storageService.getItem('userHome'));
      this.router.navigate([userHomeData.userHomeLink]);
    }
  }

  logout() {
    this.hamburgerOpen = false;
    if (!this.isOpsHeader) {
      this.authService.logout().then(() => {
        this.router.navigate(['home']);
      });
    } else {
      this.opsAuthService.resetSession();
      this.router.navigate(['ops/logout']);
    }

  }

  toggleNotificationNav(event) {
    event.stopPropagation();
    if (this.notificationTrayExtended) {
      this.notificationTrayExtended = false;
    } else {
      this.notificationTrayExtended = true;
    }
    if (this.navbarOpen) {
      this.navbarOpen = false;
    } else {
      this.updateNotificationTray();
    }
  }

  updateNotificationTray() {
    if (this.notificationCount > 0) {
      setTimeout(() => {
        this.notificationTray.nativeElement.scrollTop = 0;
      }, 0);

      this.navbarOpen = !this.navbarOpen;
    } else {
      this.router.navigate(['notifications/list']);
    }
  }

  toggleHamburgerNav() {
    this.hamburgerOpen = !this.hamburgerOpen;
  }

  toggleSearchNav() {
    this.toggleSearchBar = !this.toggleSearchBar;
    this.searchNavToggled.emit(this.toggleSearchBar);
  }

  getNotificationMap(alerts) {
    let map = {};
    alerts.map(e => {
      let notificationTypeAndCount = [e.notificationType, e.notificationTypeCount, e.notificationTypeAlias].join(',');
      if (map[notificationTypeAndCount] && map[notificationTypeAndCount].length) {
        map[notificationTypeAndCount].push(e);
      } else {
        let temp = [];
        temp.push(e);
        map[notificationTypeAndCount] = temp;
      }
    });
    return map;
  }

  getSystemAlerts() {
    this.notificationService.getSystemAlerts().subscribe((response: NotificationApiResponse) => {
      if (response.responseCode === '2001') {
        if (response.responseData && response.responseData.alerts && response.responseData.alerts.length > 0 || response.responseData.alerts.length == 0) {
          this.systemAlerts = response.responseData;
          this.menuItems.forEach(menu => {
            menu.subMenus.forEach(subMenu => {
              if (subMenu.category === 'Alerts') {
                subMenu.subMenuNames = this.systemAlerts.alerts;
              }
            });
          });
        }
      } else if (response.responseCode === '4002') {
        this.backEndValidationError = true;
      } else if (response.responseCode === '4001') {
        this.applicationError = true;
      }
    });
  }

  getNotification() {
    this.notificationService.loadNotificationCategoryJSONData().subscribe(res => {
      this.notificationCategories = res;
    });
    this.getSystemAlerts();
    const alertObj = {
      duration: 0,
      notificationType: 'All',
      pageNo: 0,
      pageSize: 5,
      source: 'Tray',
      action: 'GroupFetch'
    };

    this.notificationService.getNotificationList(alertObj).subscribe((response: NotificationApiResponse) => {
      if (response.responseCode === '2001') {
        if (response.responseData && response.responseData.alerts && response.responseData.alerts.length > 0 || response.responseData.alerts.length == 0) {
          this.notifications = response.responseData.alerts.filter((alert) => {
            if (alert.status.toLowerCase() !== 'completed' && alert.body != null) {
              return alert;
            }
          });
          this.notifications.forEach((element) => {
            element.timeStamp = this.getTimeStampForNotification(element.notificationDate);
          });
          this.notificationCount = response.responseData.totalReadyCount;
        } else {
          this.notificationCount = 0;
          this.notifications = [];
        }
        this.mapOfAlerts = this.getNotificationMap(this.notifications);
      } else if (response.responseCode === '4002') {
        this.backEndValidationError = true;
      } else if (response.responseCode === '4001') {
        this.applicationError = true;
      }
    });
  }

  getTimeStampForNotification(notDate) {
    // EST
    const timeStamp = new Date().getTime() - new Date(notDate).getTime();
    const timeStampDays = Math.floor(timeStamp / (1000 * 3600 * 24));
    const timeStampHrs = Math.floor(timeStamp / (1000 * 3600));
    const timeStampMins = Math.floor(timeStamp / (1000 * 60));
    const timeStampSec = Math.floor(timeStamp / (1000));
    if (timeStampDays >= 1) {
      return timeStampDays + 'd';
    } else if (timeStampHrs >= 1) {
      return timeStampHrs + 'h';
    } else if (timeStampMins >= 1) {
      return timeStampMins + 'm';
    } else if (timeStampSec >= 1) {
      return timeStampSec + 's';
    }
  }

  navigateToNotificationFlow(event, notificationId, notificationCategory, navigationDetails, navigationKey) {
    event.stopPropagation();
    this.navbarOpen = false;

    this.notificationService.updateNotificationRedirection(navigationKey, navigationDetails);

    this.notificationService.updateNotificationList(notificationId, notificationCategory)
      .subscribe((response: CommonApiResponse) => {
        if (response.responseCode === '2001') {
          if (navigationKey === '') {
            this.notifications = [];
            this.getNotification();
          }
        } else if (response.responseCode === '4002') {
          this.backEndValidationError = true;
        } else if (response.responseCode === '4001') {
          this.applicationError = true;
        }
      });
  }

  navigateNoteHome(event) {
    event.stopPropagation();
    this.navbarOpen = false;
    this.router.navigate(['notifications/list']);
  }

  loginClick() {
    this.router.navigate(['login']);
  }

  returnGroupDetails(groupString: any, delimiter: any) {
    const arr = groupString.split(delimiter);
    return arr[0];
  }

  returnGroupCountDetails(groupString: any, delimiter: any) {
    const arr = groupString.split(delimiter);
    return arr[1];
  }

  loadNotification(notificationCategoriesAliasArr: any, notificationType: any) {
    if (notificationCategoriesAliasArr != null && notificationType != null) {
      return notificationCategoriesAliasArr[notificationType];
    }

  }

  navigateToRequestAccess() {
    if (this.storageService.getItem('hostName')) {
      this.router.navigate(['request-access']);
    } else {
      this.showMultitenanceError = true;
    }
  }

  dismissTrayGroupNotification(groupId: string, groupTotalCount: number) {
    const notificationObject = {
      duration: 0,
      notificationType: 'All',
      pageNo: 0,
      pageSize: groupTotalCount,
      source: 'Tray',
      action: 'GroupDismissAll',
      group: groupId,
      notificationCategories: ['Alert']
    };
    this.notificationService.dismissTrayGroupNotification(notificationObject).subscribe((response: CommonApiResponse) => {
      if (response.responseCode === '2001') {
        this.getNotification();
        this.router.navigateByUrl(this.router.url);
      }
    });
  }

  ngOnDestroy() {
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }
  }

}
