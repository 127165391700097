import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-support',
  templateUrl: './customer-support.component.html',
  styleUrls: ['./customer-support.component.scss']
})
export class CustomerSupportComponent implements OnInit {

  constructor() {
    // constructor
  }

  ngOnInit() {
    window.scroll(0, 0);
  }

}
