import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdamConf } from '@app/app.config';

@Component({
  selector: 'app-user-setup',
  templateUrl: './user-setup.component.html',
  styleUrls: ['./user-setup.component.scss']
})
export class UserSetupComponent implements OnInit {
  currentPage: string;
  pageNumber: string = "1";
  adam_labels: any;
  routerLink: string;

  constructor(private router: Router) {
    this.routerLink = this.router.routerState.snapshot.url;
    if (this.routerLink.includes("/complete-registration/")) {
      this.currentPage = 'changePassword';
      this.pageNumber = "1";
    } else if (this.routerLink.includes("/complete-registration-topics/")) {
      this.currentPage = 'topic';
      this.pageNumber = "2";
    } else if (this.routerLink.includes("/complete-registration-notifications/")) {
      this.currentPage = 'notification';
      this.pageNumber = "3";
    } else if (this.routerLink.includes("/complete-registration-login/")) {
      this.currentPage = 'login';
      this.pageNumber = "4";
    }
    this.isUserSetupContinue(this.currentPage);
  }

  public ngOnInit(): void {
    this.adam_labels = AdamConf;
  }

  public isUserSetupContinue(isUserSetupChange: string): void {
    this.currentPage = isUserSetupChange;
    if (this.currentPage === 'changePassword') {
      this.pageNumber = "1";
    } else if (this.currentPage === 'topic') {
      this.pageNumber = "2";
    } else if (this.currentPage === 'notification') {
      this.pageNumber = "3";
    } else if (this.currentPage === 'login') {
      this.pageNumber = "4";
    }
  }
}
