import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { MenuData } from './models/auth-model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  unAuthenticatedPages = ['login', 'request-access', 'home', 'forgot-password', 'reset-password', 'reset-success', 'complete-registration', 'complete-registration-topics', 'complete-registration-notifications', 'complete-registration-login', 'request-access', 'reset-password', 'user-setup', 'password-expired'];
  commonPages = ['search-results', 'privacy-policy', 'unauthorized', 'customer-support', 'legal-notice', 'california-compliance','pricing-disclosure'];
  opsPages = ['ops'];
  public menuItems: MenuData;
  constructor(private router: Router, private authService: AuthService
  ) {
    this.authService.menuItems$.subscribe(userData => {
      this.menuItems = userData;
    });
  }
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve) => {
      let split = state.url.split('/');
      let chkUrl = (split[1]) ? split[1] : '';

      let user = this.authService.getCurrentUser();

      if (user) {
        if (this.unAuthenticatedPages.indexOf(chkUrl) !== -1 || !this.checkPermission(next, this.menuItems)) {
          this.router.navigate(['dashboard']);
          resolve(false);
        } else {
          resolve(true);
        }
      }
      else {
        if (this.unAuthenticatedPages.indexOf(chkUrl) !== -1 ||
          this.commonPages.indexOf(chkUrl) !== -1 ||
          this.opsPages.indexOf(chkUrl) !== -1) {
          resolve(true);
        }
        else {
          this.router.navigate(['home']);
          resolve(false);
        }
      }
    });
  }

  public checkPermission(route: ActivatedRouteSnapshot, menu: MenuData): boolean {
    if (route.data.roles && menu.permissionType && route.data.roles.indexOf(menu.permissionType) !== -1) {
      return true;
    } else {
      if (!route.data.roles) {
        return true;
      }
      return false;
    }

  }

}