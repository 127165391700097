import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { ChartDataItem, ChartConfig, BarConfig, MousePosition } from '@app/shared/models/grouped-bar-chart.model';

@Component({
  selector: 'app-shared-grouped-bar-chart',
  templateUrl: './grouped-bar-chart.component.html',
  styleUrls: ['./grouped-bar-chart.component.scss']
})
export class GroupedBarChartComponent implements OnChanges {

  @Input() boxesOrderedReplenishedBarData: ChartDataItem[];
  @Input() barConfig: BarConfig;
  @Input() chartMetaData: ChartConfig;
  @ViewChild('chartContainer', { static: false })
  private chartContainer: ElementRef;

  constructor() { }

  public ngOnChanges(): void {
    if (this.boxesOrderedReplenishedBarData && this.boxesOrderedReplenishedBarData.length > 0) {
      setTimeout(() => {
        this.initChart(this.boxesOrderedReplenishedBarData, this.barConfig, this.chartMetaData);
      }, 100);
    }
  }

  private initChart(data, colorConfig, chartMetaData): void {
    const width = this.chartContainer.nativeElement.offsetWidth - chartMetaData.margin.left - chartMetaData.margin.right;
    const height = this.chartContainer.nativeElement.offsetHeight - chartMetaData.margin.bottom - chartMetaData.margin.top;
    const x0 = d3.scale.ordinal().rangeRoundBands([0, width], .1);
    const x1 = d3.scale.ordinal();
    const y = d3.scale.linear().range([height, 0]);
    const xAxis = d3.svg.axis()
      .scale(x0)
      .tickSize(0)
      .orient('bottom');
    const yAxis = d3.svg.axis()
      .scale(y)
      .ticks(4)
      .orient('left');
    const color = (key) => {
      return colorConfig[key];
    };

    let svg = d3.select(this.chartContainer.nativeElement)
      .html('')
      .append('svg')
      .attr('width', width + chartMetaData.margin.left + chartMetaData.margin.right)
      .attr('height', height + chartMetaData.margin.bottom + chartMetaData.margin.top);

    svg.append('g')
      .attr('class', 'y yLabel')
      .append('text')
      .attr('width', height)
      .attr('y', 6)
      .attr('dy', '.71em')
      .style('font-weight', 'bold')
      .style('font-size', '16px')
      .style('fill', '#63666A')
      .attr('transform', 'translate(0, ' + (height / 2 + chartMetaData.margin.top) + ') rotate(-90)');

    svg = svg.append('g')
      .attr('width', width)
      .attr('height', height)
      .attr('transform', 'translate(' + chartMetaData.margin.left + ',' + chartMetaData.margin.top + ')');

    const categoryNames = data.map((d) => d.category);
    const barNames = data[0].barChartData.map((d) => d.label);
    x0.domain(categoryNames);
    x1.domain(barNames).rangeRoundBands([0, x0.rangeBand()]);
    y.domain([0, d3.max(data, (category) => d3.max(category.barChartData, (d) => d.value) + 1 )]);

    svg.append('g')
      .attr('class', 'x axis xAxis')
      .attr('transform', 'translate(0,' + height + ')')
      .style('font-weight', 'bold')
      .style('fill', '#63666A')
      .call(xAxis);

    svg.append('g')
      .attr('class', 'y axis yAxis')
      .style('opacity', '0')
      .style('font-weight', 'bold')
      .style('fill', '#63666A')
      .call(yAxis);

    svg.selectAll('g.yAxis g.tick')
      .append('line')
      .attr('class', 'gridline y')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', width)
      .attr('stroke-width', 2)
      .attr('stroke-dasharray', '5, 5')
      .style('opacity', '1')
      .style('stroke', '#d0cfcd')
      .attr('y2', 0);

    // Add styles to X axis ticks
    svg.selectAll('g.xAxis g.tick text')
      .style('text-anchor', 'end')
      .style('font-size', '10px')
      .style('font-family', 'Noto Sans Regular')
      .attr('transform', 'translate(' + (width / data.length) / 6 + ', 0),rotate(-45)');

    svg.selectAll('.domain').style('fill', '#fff');

    let slice = svg.selectAll('.slice')
      .data(data)
      .enter().append('g')
      .attr('class', 'g')
      .attr('transform', (d) => 'translate(' + x0(d.category) + ',0)');

    slice = slice.selectAll('barItem')
      .data((d) => d.barChartData)
      .enter()
      .append('g')
      .attr('class', 'barItem');

    slice.append('rect')
      .attr('width', x1.rangeBand())
      .attr('x', (d) =>  x1(d.label))
      .style('fill', (d) =>  color(d.label))
      .attr('y', (d) =>  y(0))
      .attr('height', (d) =>  height - y(0)) .on('mouseover', (d) => {
        const div = d3.select('body').append('div')
          .attr('class', 'tooltip-chart-line')
          .style('opacity', 0);
        div.transition()
          .duration(200)
          .style('opacity', .9);
        div.html(this.getTooltipContent(d))
          .style('left', () => {
            const pos = this.positionTooltip(
              {
                x: d3.event.pageX,
                y: d3.event.pageY
              });
            return (pos.left + 10) + 'px';
          })
          .style('top', (d3.event.pageY - 28) + 'px')
          .style('z-index', '10001');
      })
      .on('mouseout', (d) => {
        const tooltip = document.getElementsByClassName('tooltip-chart-line');
        while (tooltip[0]) {
          tooltip[0].parentNode.removeChild(tooltip[0]);
        }
        const div = d3.select('tooltip-chart-line');
        div.transition()
          .duration(500)
          .style('opacity', 0);
      });

    slice.selectAll('rect')
      .transition()
      .delay((d) => {
        let array = new Uint32Array(1);
        window.crypto.getRandomValues(array);
        return Number(array[0].toString()[0]) * 100;
      })
      .duration(1000)
      .attr('y', (d) => {
        return y(d.value) === height ? y(d.value) - 1 : y(d.value);
      })
      .attr('height', (d) => {
        let h = height - y(d.value);
        return h;
      });
    svg.select('.y').transition().duration(500).delay(1300).style('opacity', '1');
  }

  private positionTooltip(mouse): MousePosition {
    const box = document.querySelector('.graph-table');
    if (box) {
      const style = getComputedStyle(box);
      const toolwidth = parseInt(style.width);
      // Distance of element from the right edge of viewport
      if (window.innerWidth - (mouse.x + toolwidth) < 100) { // If tooltip exceeds the X coordinate of viewport
        mouse.x = mouse.x - toolwidth - 30;
      }
      return {
        top: mouse.y,
        left: mouse.x
      };
    }

  }

  private getTooltipContent(d): string {
    let tooltipContent = '';
    if (d.toolTipInfo) {
      tooltipContent = tooltipContent + '<table class="graph-table">';
      d.toolTipInfo.forEach(element => {
        tooltipContent += `<tr><td class="title">${element.label}:</td><td class="data-value"> ${element.value} </td></tr>`;
      });
      tooltipContent = tooltipContent + '</table>';
    }
    return tooltipContent;
  }

}