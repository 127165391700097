import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../core/base.service';
import { UserRolesPermissionMappingWrapper, UserRoleWrapper, UsersPermissionTypeWrapper } from './shared/models/user-management-model';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(private baseService: BaseService) { }
  getUserRoles():Observable<UserRoleWrapper> {
    return this.baseService.getViaParam('user-management/api/v1/users/roles', null);
  }
  getUserRolesPermissionMapping():Observable<UserRolesPermissionMappingWrapper> {
    return this.baseService.getViaParam('user-management/api/v1/users/rolepermission', null);
  }

  getUserpermissionType():Observable<UsersPermissionTypeWrapper> {
    return this.baseService.getViaParam('user-management/api/v1/users/permissiontype', null);
  }

  getPermissionTypesTableData() {
    return this.baseService.getViaParam('user-management/api/v1/users/staffpermissions/permissiontypes', null);
  }

  getManagerUsers(institutionId, facilityId) {
    return this.baseService.getViaParam('user-management/api/v1/users/institutions/' + institutionId + '/facilities/' + facilityId, null);
  }

  getAccessRequests() {
    return this.baseService.getViaParam('user-management/api/v1/users/accessRequests', null);
  }

  postAddUser(userList, userAction) {
    return this.baseService.sheduleUpload('user-management/api/v1/users', userList,  {'user-action': userAction});
  }

  postApiRequests(url, params, userAction) {
    return this.baseService.postViaObjectParam(url, params, {'user-action': userAction});
  }

  postNotifUpdates(workflowId) {
    return this.baseService.postViaObjectParam('notification-management/api/v1/notify/alerts/workflows?workFlowId=' + workflowId, null);
  }
  getUserDetails() {
    return this.baseService.getViaParam('user-management/api/v1/users/profile', null);
  }
  postUserDetailsEdit(params, userAction) {
    return this.baseService.postViaObjectParam('user-management/api/v1/users/profile', params, {'user-action': userAction});
  }
  getTopics() {
    return this.baseService.getViaParam('user-management/api/v1/users/topics', null);
  }
  getUserSetupTopic(topics, email) {
    return this.baseService.postViaObjectParam('user-management/api/v1/public/topics/' + email, topics);
  }
  postTopics(topics) {
    return this.baseService.postViaObjectParam('user-management/api/v1/users/topics', topics);
  }
  postUserSetupTopics(topics, email) {
    return this.baseService.postViaObjectParam('user-management/api/v1/public/topicspreference/' + email, topics);
  }
  getQuickLinksApi() {
    return this.baseService.getViaParam('user-management/api/v1/users/quickLinks', null);
  }
  getMyPageGraphData() {
    return this.baseService.getViaParam('analytics-management/api/v1/analytics/myPage/getData', null);
  }
  postQuickLink(links) {
    return this.baseService.postViaObjectParam('user-management/api/v1/users/quickLinks', links);
  }
  getNotifi() {
    return this.baseService.getViaParam('user-management/api/v1/users/profile', null);
  }
  postNotifi(notification) {
    return this.baseService.postViaObjectParam('user-management/api/v1/users/profile', notification);
  }
  postUserSetupNotif(notification, email) {
    return this.baseService.postViaObjectParam('user-management/api/v1/public/notificationpreference/' + email, notification);
  }

  getNotificationPreferences() {
    return this.baseService.getViaParam('notification-management/api/v1/notify/notifications/all', null);
  }

  postNotificationPreferences(notificationPreferences) {
    return this.baseService.postViaObjectParam('notification-management/api/v1/notify/save/notificationStatus', notificationPreferences);
  }
}
