import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-infinite-scroll',
  template: `<ng-content></ng-content><div #anchor></div>`
})
export class InfiniteScrollComponent implements OnInit, OnDestroy {

  @Input() options = {};
  @Output() scrolled = new EventEmitter();

  private anchorData: ElementRef;
  @ViewChild('anchor', { static: false }) set content(content: ElementRef) {
    this.anchorData = content;
    this.observer.observe(this.anchorData.nativeElement);
  }
  private observer: IntersectionObserver;
  constructor(private host: ElementRef) { }


  public ngOnInit(): void {
    const options = {
      root: null,
      ...this.options
    };
    // get element() {
    //   return this.host.nativeElement;
    // }

    this.observer = new IntersectionObserver(([entry]) => {
      entry.isIntersecting && this.scrolled.emit();
    }, options);

  }

  public ngOnDestroy(): void {
    this.observer.disconnect();
  }

}
