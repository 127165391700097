import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AdamConf } from '@app/app.config';

@Component({
  selector: 'app-tile-magnifier',
  templateUrl: './tile-magnifier.component.html',
  styleUrls: ['./tile-magnifier.component.scss']
})
export class TileMagnifierComponent implements OnInit {
  @Input() showMagnifiedViewPopupData: any;
  @Output() showMagnifiedTilePopup = new EventEmitter<boolean>();
  public adamLabels: any;

  constructor() {
    this.adamLabels = AdamConf;
  }

  public ngOnInit(): void {
    // OnInit 
  }

  public showMagnifiedPopup(event: any): void {
    this.showMagnifiedViewPopupData = event;
    this.showMagnifiedTilePopup.emit(this.showMagnifiedViewPopupData);
  }

}
