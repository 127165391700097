import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { EditorModule } from 'primeng/editor';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { ProgressBarModule } from 'primeng/progressbar';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputMaskModule } from 'primeng/inputmask';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { TreeTableModule } from 'primeng';
import { SliderModule } from 'primeng/slider';
import { AccordionModule } from 'primeng/accordion';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CalendarModule,
    DropdownModule,
    CardModule,
    TableModule,
    PaginatorModule,
    ButtonModule,
    InputTextModule,
    EditorModule,
    MessageModule,
    ToastModule,
    ProgressBarModule,
    DialogModule,
    TabViewModule,
    AutoCompleteModule,
    SelectButtonModule,
    InputMaskModule,
    RadioButtonModule,
    TooltipModule,
    CheckboxModule,
    TreeTableModule,
    SliderModule,
    AccordionModule,
  ],
  exports: [
    CalendarModule,
    DropdownModule,
    CardModule,
    TableModule,
    PaginatorModule,
    ButtonModule,
    InputTextModule,
    EditorModule,
    MessageModule,
    ToastModule,
    ProgressBarModule,
    DialogModule,
    TabViewModule,
    AutoCompleteModule,
    SelectButtonModule,
    InputMaskModule,
    RadioButtonModule,
    TooltipModule,
    CheckboxModule,
    TreeTableModule,
    SliderModule,
    AccordionModule
  ]
})
export class PrimengModule { }
