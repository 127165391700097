import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-success',
  templateUrl: './reset-success.component.html',
  styleUrls: ['./reset-success.component.scss']
})
export class ResetSuccessComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
    // onInit
  }

  goToLogin() {
    this.router.navigate(['login']);
  }

}
