import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdamConf } from '@app/app.config';
import { AuthService } from 'src/app/auth/auth.service';
import { ForgotPasswordAPIResponse, LoginAPiResponse, ResetPasswordAPIResponse } from '../models/forgot-pwd-model';
import { StorageService } from '../storage.service';

@Component({
  selector: 'app-resetpwd',
  templateUrl: './resetpwd.component.html',
  styleUrls: ['./resetpwd.component.scss']
})
export class ResetpwdComponent implements OnInit {

  resetPwdForm: FormGroup;
  resetPwdval: any;
  invalidUserOrPwd: boolean = false;
  loggedUser: any = null;
  pswdNoMatch: boolean = false;
  passedArgs: any = {};
  resetSuccess: boolean = false;
  resetErrorMsg: any = '';
  resetProgress = false;
  pswdNoMatchEr: boolean = false;
  emailAlreadyVerified: boolean = false;
  showCheck: boolean = true;
  routeToForgotPwd: boolean = false;
  showPwdPolicy: boolean = true;
  placeholderValue: string = 'Create password';
  placeholderValueConfirm: string = 'Confirm password';
  adam_labels: any;
  regEx: any;
  routeToPwdExpired: any;
  expiredPwdChangeSuccess: boolean = false;
  currentPwdError: any;

  @Output() isUserSetupNext = new EventEmitter<string>();
  routerLink: string;
  isUserSetup: boolean;
  fname: any;
  lname: any;
  requestOrigin: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authservice: AuthService,
    private storageService: StorageService
  ) {
  }

  public ngOnInit(): void {
    this.requestOrigin = this.storageService.getItem('hostName');
    this.adam_labels = AdamConf;
    this.regEx = new RegExp(/^((?=.*?[a-zA-Z])(?=.*?\d)(?=.*?[@!#$%^&*()\'\-\/\\~|,;:\]\[_=+`?><}{"])).{8,30}$/);
    this.routerLink = this.router.routerState.snapshot.url;

    if (this.routerLink.includes('/complete-registration')) {
      this.isUserSetup = true;
    } else {
      this.isUserSetup = false;
    }
    this.resetPwdForm = new FormGroup({
      email: new FormControl(''),
      currentPassword: new FormControl(''),
      createPassword: new FormControl(''),
      confirmPassword: new FormControl('')
    });

    this.route.paramMap.subscribe(params => {
      this.passedArgs['uname'] = params.get('username');
      this.passedArgs['tmpPwd'] = params.get('tmppwd');
      this.passedArgs['code'] = params.get('code');
      this.passedArgs['token'] = params.get('token');
      this.passedArgs['fname'] = params.get('fname');
      this.fname = this.passedArgs['fname'];
      this.passedArgs['lname'] = params.get('lname');
      this.lname = this.passedArgs['lname']

    });
    if (this.passedArgs.uname != null) {
      this.resetPwdForm.controls.email.setValue(this.passedArgs.uname.toLowerCase());
    }
    var currentURL = window.location.href;
    if (currentURL.includes('reset-password')) {
      this.routeToForgotPwd = true;
      this.placeholderValue = 'Create new password';
      this.placeholderValueConfirm = 'Confirm new password';
    } else if (currentURL.includes('password-expired')) {
      this.routeToPwdExpired = true;
      this.placeholderValue = 'Create new password';
      this.placeholderValueConfirm = 'Confirm new password';
    } else {
      this.routeToForgotPwd = false;
    }

  }

  confirmPwdChk() {
    this.resetErrorMsg = '';
    this.resetPwdval = this.resetPwdForm.value;
    if (this.resetPwdval.createPassword !== '') {
      this.showPwdPolicy = true;
      if (this.regEx.test(this.resetPwdForm.value.createPassword)) {
        this.showPwdPolicy = false;
      }
    }
    if (this.resetPwdval.confirmPassword !== '' && this.resetPwdval.createPassword !== '') {
      if (this.resetPwdval.confirmPassword !== this.resetPwdval.createPassword) {
        this.resetPwdForm.controls['confirmPassword'].markAsTouched();
        this.resetPwdForm.controls['confirmPassword'].setErrors({ 'nomatch': true })
        this.pswdNoMatch = false;
        this.pswdNoMatchEr = true;
      } else {
        this.resetPwdForm.controls['confirmPassword'].setErrors(null);
        this.resetPwdForm.controls['createPassword'].setErrors(null);
      }
      if (this.resetPwdval.confirmPassword === this.resetPwdval.createPassword) {
        this.pswdNoMatch = true;
        this.pswdNoMatchEr = false;
      }
    }
    if (this.resetPwdval.confirmPassword === '') {
      this.pswdNoMatchEr = false;
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.resetPwdForm.controls; }

  public validateForm(): boolean {
    this.resetErrorMsg = '';
    let valid = true;
    if (this.resetPwdForm.value.createPassword === '' || this.resetPwdForm.value.createPassword === undefined) {
      this.resetPwdForm.controls.createPassword.setErrors({ required: true });
      this.showPwdPolicy = false;
      valid = false;
    }
    if (this.routeToPwdExpired) {
      if (this.resetPwdForm.value.currentPassword === '' || this.resetPwdForm.value.currentPassword === undefined) {
        this.resetPwdForm.controls.currentPassword.setErrors({ required: true });
        valid = false;
      }
    }
    if (this.resetPwdForm.value.confirmPassword === '' || this.resetPwdForm.value.confirmPassword === undefined) {
      this.resetPwdForm.controls.confirmPassword.setErrors({ required: true });
      valid = false;
    }
    if (this.resetPwdForm.value.createPassword !== '' && this.resetPwdForm.value.createPassword !== undefined) {
      if (!this.regEx.test(this.resetPwdForm.value.createPassword)) {
        this.resetPwdForm.controls.createPassword.setErrors({ pattern: true });
        this.pswdNoMatchEr = false;
        valid = false;
      }
    }
    return this.checkConfirmPassword(valid);
  }

  private checkConfirmPassword(valid: boolean): boolean {
    if (this.resetPwdForm.value.confirmPassword !== '' && this.resetPwdForm.value.confirmPassword !== undefined) {
      if (!this.regEx.test(this.resetPwdForm.value.confirmPassword)) {
        this.resetPwdForm.controls.confirmPassword.setErrors({ pattern: true });
        valid = false;
      }
    }
    if (this.resetPwdForm.value.confirmPassword !== this.resetPwdForm.value.createPassword) {
      this.resetPwdForm.controls['createPassword'].setErrors({ 'nomatch': true });
      valid = false;
    }
    return valid
  }

  public onsetPwdSubmit(): boolean {
    this.resetErrorMsg = '';
    if (!this.validateForm()) {
      return false;
    }
    if (this.routeToForgotPwd) {
      this.resetPwdFn();
    } else if (this.routeToPwdExpired) {
      this.passwordExpiriedFn();
    } else {
      this.setUppwdfn();
    }
  }

  public setUppwdfn(): void {
    this.onsetPwdSubmitConfig();
    let queryParams = {
      "email": this.passedArgs.uname.toUpperCase(),
      "oldPassword": this.passedArgs.tmpPwd,
      "password": this.resetPwdval.createPassword.trim(),
      "token": this.passedArgs.token,
      "requestOrigin": this.requestOrigin
    };
    this.authservice.signUp(queryParams).subscribe((response: LoginAPiResponse) => {
      if (response.responseCode === '2001') {
        this.setUpPwdSuccess(response);
      } else if (response.responseCode === '4002') {
        if (response.responseMessage === 'Passwords used for last 5 iterations or within a span of 365 days cannot be re-used.') {
          this.resetErrorMsg = response.responseMessage;
        } else {
          this.resetPwdForm.disable();
          this.showCheck = true;
          this.resetErrorMsg = response.responseMessage;
        }
      } else {
        this.resetPwdForm.disable();
        this.showCheck = true;
        this.resetErrorMsg = 'Password setup process failed. Please contact administrator.';
      }
    });
  }

  passwordExpiriedFn() {
    let queryParams = {
      "email": this.passedArgs.uname.toUpperCase(),
      "oldPassword": this.resetPwdForm.value.currentPassword,
      "password": this.resetPwdval.createPassword.trim(),
      "requestOrigin": this.requestOrigin
    };
    this.authservice.expiredPwdChange(queryParams).subscribe((response: ForgotPasswordAPIResponse) => {
      if (response.responseCode === '2001') {
        this.resetSuccess = true;
        this.expiredPwdChangeSuccess = true;
      } else if (response.responseCode === '4002') {
        if (response.responseMessage === 'Current password is incorrect. Please try again.') {
          this.resetPwdForm.controls['currentPassword'].setErrors({ 'nomatch': true });
          this.currentPwdError = 'Current password is incorrect.';
        } else {
          this.resetErrorMsg = response.responseMessage;
        }
      } else {
        this.resetErrorMsg = 'Password reset process failed. Please contact administrator.';
      }
    });
  }
  resetPwdFn() {
    let queryParams = {
      "email": this.resetPwdForm.value.email.toUpperCase(),
      "oldPassword": this.passedArgs.code,
      "password": this.resetPwdForm.value.createPassword.trim(),
      "token": this.passedArgs.token,
      "requestOrigin": this.requestOrigin
    };
    this.authservice.resetPassword(queryParams)
      .subscribe((response: ResetPasswordAPIResponse) => {
        if (response.responseCode === '2001') {
          this.resetSuccess = true;
        } else if (response.responseCode === '4002') {
          this.resetPwdError(response);
          this.resetErrorMsg = response.responseMessage;
        }
      });
  }

  resetPwdError(e) {
    this.resetSuccess = false;
    this.resetProgress = false;
    this.pswdNoMatch = false;
  }

  onsetPwdSubmitConfig() {
    if (this.resetPwdForm.invalid) {
      return false;
    }
    this.resetProgress = true;
    this.resetPwdval = this.resetPwdForm.value;
  }
  setUpPwdSuccess(user) {
    this.resetPwdForm.reset();
    this.resetPwdForm.enable();
    if (this.isUserSetup === true) {
      this.router.navigate(['complete-registration-login/' + this.passedArgs['uname'] + '/' + this.passedArgs['tmpPwd'] + '/' + this.passedArgs['token'] + '/' + this.fname + '/' + this.lname]);
    }
  }

  setupPwdError(e) {
    this.showCheck = false;
    this.resetProgress = false;
  }

  resetForm() {
    this.resetPwdForm.reset();
    this.router.navigate(['home']);
  }

  navigateSuccess() {
    this.authservice.logout().then(data => {
      this.router.navigate(['reset-success']);
    });
  }

  logout() {
    this.authservice.logout().then(data => {
      console.log('logged out');
    });
  }

  cancelRequest() {
    this.router.navigate(['login']);
  }
}
