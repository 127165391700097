import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'customPad'
})
export class CustomPadPipe implements PipeTransform {
  // 2020-02-27T11:48:49.740+0000
  transform(value: any, args1?: any, args2?: any): any {
    // these are the format passing to angular core date pipe
    let pad = 2;
    let padStr = "0";
    if(args1) {
        pad = args1;
    }
    if(args2) {
        padStr = args2;
    }

    if (value !== null && value !== undefined) {
        value = value.toString();
        value = value.padStart(pad, padStr);
    }
    return value;
  }
}
