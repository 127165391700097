import { Injectable } from '@angular/core';
import { BaseService } from '../../base.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DropDownAPIResponse, DropDownParamInfo, FilterApiResponse, FilterListInfo, HospitalKPIApiInfo, TileDataApiResponse, TileFilterInfo } from '../models/clinical-dashboard-model';

@Injectable({
  providedIn: 'root'
})
export class ClinicalService {

  public preferenceCardAccuracyFilter$: BehaviorSubject<any>;
  public orScanningFilter$: BehaviorSubject<any>;
  public productUsageAndReturnsFilter$: BehaviorSubject<any>;
  public financialOverviewFilter$: BehaviorSubject<any>;
  public filterPopupState$: BehaviorSubject<any>;

  constructor(private readonly baseService: BaseService) {
    this.preferenceCardAccuracyFilter$ = new BehaviorSubject<any>(null);
    this.orScanningFilter$ = new BehaviorSubject<any>(null);
    this.productUsageAndReturnsFilter$ = new BehaviorSubject<any>(null);
    this.financialOverviewFilter$ = new BehaviorSubject<any>(null);
    this.filterPopupState$ = new BehaviorSubject<any>(null);
  }

  public getClinicalTowerKpis(tileName: string): Observable<HospitalKPIApiInfo> {
    return this.baseService.getViaParam('analytics-management/api/v1/ControlTower/HospitalKpis?area=' + tileName, null);
  }

  public getTileFilterDropdownList(url: string): Observable<DropDownAPIResponse> {
    return this.baseService.getViaParam(
      'analytics-management/api/v1/' + url,
      null
    );
  }

  public fetchTileFilteredDropdownList(url: string, data: DropDownParamInfo): Observable<DropDownAPIResponse> {
    return this.baseService.postViaObjectParam(
      'analytics-management/api/v1/' + url,
      data
    );
  }

  public getTileDataFilters(tileName: string): Observable<FilterApiResponse> {
    return this.baseService.getViaParam('analytics-management/api/v1/ControlTower/TileDataFilters?tileName=' + tileName, null);
  }

  public getClinicalTileData(params: TileFilterInfo): Observable<TileDataApiResponse> {
    return this.baseService.postViaObjectParam('analytics-management/api/v1/ControlTower/TileData', params);
  }

  public setPreferenceCardAccuracyFilter(data: FilterListInfo[]): void {
    this.preferenceCardAccuracyFilter$.next(data);
  }

  public setOrScanningFilter(data: FilterListInfo[]): void {
    this.orScanningFilter$.next(data);
  }

  public setProductUsageAndReturnsFilter(data: FilterListInfo[]): void {
    this.productUsageAndReturnsFilter$.next(data);
  }

  public setFinancialOverviewFilter(data: FilterListInfo[]): void {
    this.financialOverviewFilter$.next(data);
  }

  public setFilterPopupState(data: any): void {
    this.filterPopupState$.next(data);
  }

}
